import React, { useState } from "react";
import { Input } from "reactstrap";
import utils from "../../utils";

const ReportThresholds = (props) => {
    const { 
        foodQuality, 
        isReport,
        t
    } = props;

    const [alarmUp, setAlarmUp] = useState(props.alarmUp);
    const [warningUp, setWarningUp] = useState(props.warningUp);
    const [warningDown, setWarningDown] = useState(props.warningDown);
    const [alarmDown, setAlarmDown] = useState(props.alarmDown);
    const [alarmUpError, setAlarmUpError] = useState(props.alarmUpError);
    const [warningUpError, setWarningUpError] = useState(props.warningUpError);
    const [warningDownError, setWarningDownError] = useState(props.warningDownError);
    const [alarmDownError, setAlarmDownError] = useState(props.alarmDownError);


    const onInputChange = (e) => {
        const target = e.target;
        const errors = {
            alarmUp: false,
            warningUp: false,
            warningDown: false,
            alarmDown: false
        }
    
        switch (target.name) {
            case "alarmUp":
                errors.alarmUp = target.value === "" || !isFinite(target.value);
                setAlarmUp(target.value);
            break;
            case "warningUp":
                errors.warningUp = target.value === "" || !isFinite(target.value);
                setWarningUp(target.value);
            break;
            case "warningDown":
                errors.warningDown = target.value === "" || !isFinite(target.value);
                setWarningDown(target.value);
            break;
            case "alarmDown":
                errors.alarmDown = target.value === "" || !isFinite(target.value);
                setAlarmDown(target.value);
            break;
            default:
                break;
        }

        setAlarmUpError(errors.alarmUp);
        setWarningUpError(errors.warningUp);
        setWarningDownError(errors.warningDown);
        setAlarmDownError(errors.alarmDown);
    
        // Call the parent's onInputChange if provided
        if (props.onInputChange) {
            props.onInputChange({
                alarmUp: target.name === "alarmUp" ? target.value : alarmUp,
                warningUp: target.name === "warningUp" ? target.value : warningUp,
                warningDown: target.name === "warningDown" ? target.value : warningDown,
                alarmDown: target.name === "alarmDown" ? target.value : alarmDown,
                thresholdsError: Object.values(errors).some(error => error)
            });
        }
    };

    return (
        <div
            className={`report-thresholds ${!isReport || foodQuality === 0
                    ? "d-none"
                    : ""
                }`}
        >
            <span className="report-threshold-name">
                {t("report.highAlarmThreshold") + ":"}
            </span>
            <Input
                style={{
                    background: !isReport
                        ? utils.getArgonColors("light") + "4F"
                        : alarmUpError
                            ? utils.getArgonColors("danger") + "4F"
                            : "#FFF",
                }}
                name="alarmUp"
                className="report-threshold-value m-2"
                placeholder="High value alarm threshold"
                value={alarmUp}
                onChange={(e) => onInputChange(e)}
                disabled={!isReport}
            />

            <span className="report-threshold-name">
                {t("report.highWarningThreshold") + ":"}
            </span>
            <Input
                style={{
                    background: !isReport
                        ? utils.getArgonColors("light") + "4F"
                        : warningUpError
                            ? utils.getArgonColors("danger") + "4F"
                            : "#FFF",
                }}
                name="warningUp"
                className="report-threshold-value m-2"
                placeholder="High value warning threshold"
                value={warningUp}
                onChange={(e) => onInputChange(e)}
                disabled={!isReport}
            />
            <span className="report-threshold-name">
                {t("report.lowWarningThreshold") + ":"}
            </span>
            <Input
                style={{
                    background: !isReport
                        ? utils.getArgonColors("light") + "4F"
                        : warningDownError
                            ? utils.getArgonColors("danger") + "4F"
                            : "#FFF",
                }}
                name="warningDown"
                className="report-threshold-value m-2"
                placeholder="Low value warning threshold"
                value={warningDown}
                onChange={(e) => onInputChange(e)}
                disabled={!isReport}
            />
            <span className="report-threshold-name">
                {t("report.lowAlarmThreshold") + ":"}
            </span>
            <Input
                style={{
                    background: !isReport
                        ? utils.getArgonColors("light") + "4F"
                        : alarmDownError
                            ? utils.getArgonColors("danger") + "4F"
                            : "#FFF",
                }}
                name="alarmDown"
                className="report-threshold-value m-2"
                placeholder="Low value alarm threshold"
                value={alarmDown}
                onChange={(e) => onInputChange(e)}
                disabled={!isReport}
            />
        </div>
    );
};

export default ReportThresholds;

