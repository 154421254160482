import React, { Component } from "react";
import utils from "../../utils";
import { LicenseInfo } from "./LicenseInfo";
import Selector from "components/Selector";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
} from "reactstrap";

export class ActiveLicenses extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedSite: 0,
      sitesFilter: null,
      unlicensedOnly: false,
    };
  }

  componentDidMount = () => {
    this.setLicenseHeight();
  };

  setLicenseHeight = () => {
    let r = document.getElementById("root");

    let rs = getComputedStyle(r);

    let cardHeight = rs
      .getPropertyValue("--license-card-height")
      .split("px")[0];

    Object.keys(this.props.sites).forEach((siteGuid) => {
      Object.keys(this.props.sites[siteGuid].gateways).forEach(
        (gatewayGuid) => {
          const { licenses } = this.props.sites[siteGuid].gateways[gatewayGuid];
          licenses.forEach((license) => {
            let card = document.getElementById(license.licenseGuid);
            if (card) {
              if (parseInt(cardHeight) < card.offsetHeight) {
                cardHeight = card.offsetHeight;
              }
              let r = document.getElementById("root");
              r.style.setProperty("--license-card-height", cardHeight + "px");
            }
          });
        }
      );
    });
  };

  renderGateways = (gateways, siteGuid) => {
    return (
      <div
        key={siteGuid}
        className={`${
          !this.props.showAsList && Object.keys(gateways).length > 1
            ? "license-container-gateways-standard"
            : ""
        }`}
      >
        {Object.keys(gateways).map((gatewayGuid) => {
          const gateway = gateways[gatewayGuid];
          let licensesList = gateway.licenses;

          licensesList = licensesList.filter((license) => !license.isExpired);
          licensesList = licensesList.sort((a) => (a.isActive ? -1 : 1));
          const licensesNumber = licensesList.length;

          for (let i = licensesNumber; i < 2; i++) {
            licensesList.push({ empty: true });
          }

          if (this.state.unlicensedOnly) {
            if (licensesList.filter((l) => l.isActive).length > 0) {
              return null;
            }
          }

          return (
            <Card
              key={gatewayGuid}
              className={`${
                this.props.showAsList ? "mr-4 mb-4" : "m-4"
              } license-gateway`}
            >
              <CardHeader className="text-center">
                <div className="h3 m-0">{gateway.name}</div>
                <div className="h5 m-0">
                  <span className="font-weight-light">
                    {this.props.t("gatewayTable.uniqueId") + ": "}
                  </span>
                  <span>{gateway.macAddress}</span>
                </div>
                <Button
                  color="info"
                  className={`btn-activate-license`}
                  onClick={() => {
                    this.props.selectEntities(siteGuid, gatewayGuid);
                    this.props.assignLicense();
                  }}
                >
                  {"+"}
                </Button>
              </CardHeader>
              <CardBody className="shadow pt-0 licenses-in-use">
                {licensesList.map((license, i) => {
                  let content = null;
                  let name = null;

                  if (!license.empty) {
                    content = license.content;
                    name = JSON.parse(content).name;
                  }

                  let iconResult = !license.empty
                    ? utils.getLicenseIconAndColor(license)
                    : utils.getLicenseIconAndColor({
                        gateway: 1,
                        isActive: i === 0,
                        isExpired: false,
                        isExpiring: false,
                      });

                  let icon = iconResult === null ? null : iconResult[0];
                  let iconColor = iconResult === null ? "" : iconResult[1];

                  const licenseIcon = (
                    <span
                      id={"license-card-" + i}
                      license-state={
                        i === 0
                          ? this.props.t("license.activeLicense")
                          : this.props.t("license.nextLicense")
                      }
                      className={`license-list-state-icon license-card-icon ${iconColor} text-white ${
                        license.empty ? "license-card-icon-empty" : ""
                      }`}
                    >
                      {icon}
                    </span>
                  );

                  return (
                    <Card
                      key={i}
                      id={license.licenseGuid}
                      className={`m-3 mt-4 license-card ${
                        license.empty ? "license-empty-container" : ""
                      }`}
                      onClick={() => {
                        if (license.empty) {
                          this.props.selectEntities(siteGuid, gatewayGuid);
                          this.props.assignLicense();
                        }
                      }}
                    >
                      {license.empty ? (
                        <CardBody className="license-empty-body">
                          <span className="text-light h2 text-center">
                            {licenseIcon}
                            {this.props.t("license.noActiveLicense")}
                          </span>
                        </CardBody>
                      ) : (
                        <>
                          <CardHeader
                            className={`text-center pt-2 pb-2 pb-xs-4 license-header`}
                          >
                            <div>{name}</div>
                          </CardHeader>
                          <CardBody className="shadow license-body">
                            <LicenseInfo
                              license={license}
                              t={this.props.t}
                              licenseIcon={licenseIcon}
                              language={this.props.language}
                            />
                          </CardBody>
                        </>
                      )}
                    </Card>
                  );
                })}
              </CardBody>
            </Card>
          );
        })}
      </div>
    );
  };

  checkUnlicensedGateways = (site) => {
    const { gateways } = site;
    if (
      Object.keys(gateways).filter((gwGuid) => {
        const gateway = gateways[gwGuid];

        return gateway.licenses.filter((l) => l.isActive).length === 0;
      }).length === 0
    ) {
      return false;
    }
    return true;
  };

  render() {
    const { sites } = this.props;

    return (
      <div className="justify-content-center">
        <div className="unlicensed-gateway">
          <span>{this.props.t("license.show") + ":"}</span>
          <Selector
            id="unlicensedGateway"
            className="ml--1"
            status={this.state.unlicensedOnly}
            items={[
              this.props.t("license.all"),
              this.props.t("license.unlicensedGatewaysOnly"),
            ]}
            toggle={() => {
              let i = 0;
              for (let siteGuid of Object.keys(sites)) {
                const site = sites[siteGuid];

                if (this.checkUnlicensedGateways(site)) {
                  break;
                }
                i++;
              }

              if (i >= Object.keys(sites).length) {
                i = this.state.selectedSite;
              }

              this.setState({
                unlicensedOnly: !this.state.unlicensedOnly,
                selectedSite: i,
              });
            }}
          />
        </div>
        {this.props.showAsList ? (
          <div className="license-container-multi-site">
            <Card className="license-sites-list">
              <CardHeader>
                <Input
                  placeholder={this.props.t("license.sitesFilter")}
                  value={this.state.sitesFilter || ""}
                  onChange={(e) => {
                    this.setState({ sitesFilter: e.target.value });
                  }}
                />
              </CardHeader>
              <CardBody>
                {Object.keys(sites).map((siteGuid, i) => {
                  const site = sites[siteGuid];

                  if (
                    this.state.sitesFilter &&
                    this.state.sitesFilter !== "" &&
                    !site.name
                      .toLowerCase()
                      .includes(this.state.sitesFilter.toLowerCase())
                  ) {
                    return null;
                  }

                  if (this.state.unlicensedOnly) {
                    if (!this.checkUnlicensedGateways(site)) return null;
                  }

                  return (
                    <Card
                      key={siteGuid}
                      className={`license-sites-list-item${
                        this.state.selectedSite === i ? "-active" : ""
                      }`}
                      onClick={() => this.setState({ selectedSite: i })}
                    >
                      <CardBody>{site.name}</CardBody>
                    </Card>
                  );
                })}
              </CardBody>
            </Card>
            <Card className="license-container-multi-gateways border-0">
              {Object.keys(sites)
                .filter((_, i) => i === this.state.selectedSite)
                .map((siteGuid) => {
                  const site = sites[siteGuid];
                  const { gateways } = site;

                  return this.renderGateways(gateways, siteGuid);
                })}
            </Card>
          </div>
        ) : (
          <Row>
            {Object.keys(sites).map((siteGuid) => {
              const site = sites[siteGuid];
              const { gateways } = site;

              if (this.state.unlicensedOnly) {
                if (!this.checkUnlicensedGateways(site)) return null;
              }

              return (
                <Col key={siteGuid} xl={12} className="mb-4">
                  <Card>
                    <CardHeader className="license-site text-center">
                      <span className="font-weight-bold license-site-name">
                        {site.name}
                      </span>
                    </CardHeader>
                    <CardBody className="shadow pt-0">
                      {this.renderGateways(gateways, siteGuid)}
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
          </Row>
        )}
      </div>
    );
  }
}
