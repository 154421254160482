import React from "react";
import logo from "../assets/img/brand/Corp_RGB_Standard_Digital.png";

const Trademark = (props) => {
  return (
    <>
    <img
      className={props.className}
      alt=""
        src={logo}
    />
    </>

  );
};

export default Trademark;
