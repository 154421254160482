import * as apiCoreIam from "../services/core-iam";

export const getUserAttribute = async () => {
  try {
    const { settings } = await apiCoreIam.getSelfAsync();
    const mapTemperatureUnit = (unit) => {
      switch (unit) {
        case "°C":
          return "Celsius";
        case "°F":
          return "Fahrenheit";
        default:
          return unit;
      }
    };
    const mapPressureUnit = (unit) => {
      switch (unit.toLowerCase()) {
        case "bar":
          return "bar";
        case "psi":
          return "PSI";
        default:
          return unit;
      }
    };
    const mapLanguages = (lang) => {
      switch (lang.toLowerCase()) {
        case "en":
          return "English_en-GB";
        case "it":
          return "Italiano_it-IT";
        default:
          return lang;
      }
    };

    const convertedTemperatureUnit = mapTemperatureUnit(
      settings.temperatureUnit
    );
    const convertedPressureUnit = mapPressureUnit(settings.pressureUnit);
    const convertedLanguage = mapLanguages(settings.language);

    const userAttribute = {
      temp_unit: convertedTemperatureUnit,
      press_unit: convertedPressureUnit,
      language: convertedLanguage,
    };
    
    return userAttribute;
  } catch (error) {
    return null;
  }
};
