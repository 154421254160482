import { API_CALL_STARTED, API_SUCCESS, API_ERROR } from "../actions/index";

const api = (state = { isFetching: false, error: false }, action) => {
  switch (action.type) {
    case API_CALL_STARTED:
      return { ...state, isFetching: true };
    case API_SUCCESS:
      return { ...state, isFetching: false, data: action.json };
    case API_ERROR:
      if (action.text)
        return {
          ...state,
          isFetching: false,
          error: true,
          errorText: action.errorText,
        };
      else return { ...state, isFetching: false, error: true, errorText: null };
    default:
      return state;
  }
};

export default api;
