import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  ModalFooter,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Loader from "components/Loader";
import { tempOptions, NotifyContent } from "components/Notify";
import { store as reduxStore } from "index.js";
import { Store as notifyStore } from "react-notifications-component";
import * as coreApi from "services/core-iam";
import classnames from "classnames";
import keys from "configs/constants";
import "./style.css";
import { getUserAttribute } from "layouts/common";
import utils from "utils";
import { acquireToken, clearSessionStorage } from "services/backendService";

class UserLanguage extends Component {
  constructor(props) {
    super(props);

    let langList = [...reduxStore.getState().translations.list];
    let selectedLang;

    try {
      selectedLang = reduxStore.getState().user.language.split("_")[0];
    } catch (e) {
      selectedLang = "English";
    }

    langList.forEach((l) => (l.selected = selectedLang === l.text));

    this.state = { filter: "", langList };

    this.langLimit = 10;
  }

  selectLang = (i) => {
    let { langList } = this.state;
    langList.forEach((l) => (l.selected = false));

    langList[i].selected = true;
    this.setState({ langList });

    this.props.editAttribute("lang", langList[i].text + "_" + langList[i].lang);
  };

  render() {
    const { langList, filter } = this.state;

    return (
      <div className="mt-4">
        <div>{this.props.t("sidebar.selectLanguage") + ":"}</div>
        <input
          disabled={langList.length < this.langLimit}
          type="text"
          className={`form-control filter-language mt-1 ${
            langList.length < this.langLimit ? "filter-invisible" : ""
          }`}
          placeholder={
            langList.length < this.langLimit
              ? ""
              : this.props.t("sidebar.filterLanguages") + "..."
          }
          value={filter}
          onChange={(e) => {
            this.setState({ filter: e.target.value });
          }}
        ></input>
        <div className="lang-container">
          {langList.map((language, i) => {
            let code = language.lang.split("-")[1];
            if (filter !== "") {
              if (!language.text.toUpperCase().includes(filter.toUpperCase()))
                return null;
            }

            return (
              <div
                key={language.lang}
                className={`dropdown-item lang-item ${
                  language.selected ? "bg-primary text-white" : ""
                }`}
                onClick={() => {
                  if (!language.selected) this.selectLang(i);
                }}
              >
                <img
                  className="border border-0 rounded-circle mr-3"
                  src={`https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/4.1.4/flags/4x3/${code.toLowerCase()}.svg`}
                  width={25}
                  height={25}
                  alt=""
                ></img>
                <span>{language.text}</span>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

class UserUnits extends Component {
  constructor(props) {
    super(props);

    this.units = {
      temp_unit: [
        { unit: "Celsius", i: 0 },
        { unit: "Fahrenheit", i: 1 },
      ],
      press_unit: [
        { unit: "bar", i: 0 },
        { unit: "PSI", i: 1 },
      ],
      speed_unit: [{ unit: "RPM", i: 0 }],
      voltage_unit: [{ unit: "Volt", i: 0 }],
      power_unit: [{ unit: "Watt", i: 0 }],
    };

    let temperatureUnit = reduxStore.getState().user.temp_unit || "Celsius";
    let pressureUnit = reduxStore.getState().user.press_unit || "bar";
    let speedUnit = reduxStore.getState().user.speed_unit || "RPM";
    let voltageUnit = reduxStore.getState().user.voltage_unit || "Volt";
    let powerUnit = reduxStore.getState().user.power_unit || "Watt";

    const temperatureSelection = this.getUnitOrDefault('temp_unit', temperatureUnit).i;
    const pressureSelection = this.getUnitOrDefault('press_unit', pressureUnit).i;
    const speedSelection = this.getUnitOrDefault('speed_unit', speedUnit).i;
    const voltageSelection = this.getUnitOrDefault('voltage_unit', voltageUnit).i;
    const powerSelection = this.getUnitOrDefault('power_unit', powerUnit).i;

    this.state = {
      temp_unit: temperatureUnit,
      temp_sel: temperatureSelection,
      isTempOpen: false,

      press_unit: pressureUnit,
      press_sel: pressureSelection,
      isPressOpen: false,

      speed_unit: speedUnit,
      speed_sel: speedSelection,
      isSpeedOpen: false,

      voltage_unit: voltageUnit,
      voltage_sel: voltageSelection,
      isVoltageOpen: false,

      power_unit: powerUnit,
      power_sel: powerSelection,
      isPowerOpen: false,

      changes: {},
    };
  }

  getUnitOrDefault = (unit, value) => {
    let unitList = this.units[unit];
    let selection = unitList.find((u) => u.unit === value);
    if (!selection) {
      if (!keys.IS_PROD) {
        utils.debug(`${value} for ${unit} not implemented. Using default value ${unitList[0].unit}`, utils.MSG_TYPE_WARN);
      }
      
      selection = unitList[0];
    }
    return selection;
  }

  renderUnitSelector = (unitName, i) => {
    let unitOpen = "";
    let unitList = "";
    let unitSel = "";

    switch (unitName) {
      case "temperature":
        unitOpen = "isTempOpen";
        unitList = "temp_unit";
        unitSel = "temp_sel";
        break;
      case "pressure":
        unitOpen = "isPressOpen";
        unitList = "press_unit";
        unitSel = "press_sel";
        break;
      case "speed":
        unitOpen = "isSpeedOpen";
        unitList = "speed_unit";
        unitSel = "speed_sel";
        break;
      case "voltage":
        unitOpen = "isVoltageOpen";
        unitList = "voltage_unit";
        unitSel = "voltage_sel";
        break;
      case "power":
        unitOpen = "isPowerOpen";
        unitList = "power_unit";
        unitSel = "power_sel";
        break;
      default:
        return null;
    }

    return [
      <span className="mr-2 mb-2" key={"unit-name-" + i.toString()}>
        {this.props.t("sidebar." + unitName) + ":"}
      </span>,
      <Dropdown
        className="mb-2"
        key={"unit-dropdown-" + i.toString()}
        isOpen={this.state[unitOpen]}
        toggle={() => {
          this.setState({ [unitOpen]: !this.state[unitOpen] });
        }}
        disabled={this.units[unitList].length < 2}
      >
        <DropdownToggle
          caret
          className={`${this.units[unitList].length < 2 ? "text-light" : ""}`}
        >
          {this.units[unitList][this.state[unitSel]].unit}
        </DropdownToggle>
        <DropdownMenu>
          {this.units[unitList].map((u, i) => {
            return (
              <DropdownItem
                key={i}
                onClick={() => {
                  this.setState({
                    changes: { ...this.state.changes, [unitList]: true },
                    [unitSel]: i,
                  });
                  this.props.editAttribute(
                    unitList,
                    this.units[unitList][i].unit
                  );
                }}
              >
                {u.unit}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </Dropdown>,
    ];
  };

  render() {
    const unitsList = ["temperature", "pressure", "speed", "voltage", "power"];

    return (
      <div className="pt-4 preferences-container">
        {unitsList.map((u, i) => this.renderUnitSelector(u, i))}
      </div>
    );
  }
}

class Preferences extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0,
      isLoading: false,
      attributes: {},
      changes: {},
    };

    getUserAttribute().then((attribute) => {
      let userAttribute = {};
      for (let attr in attribute) {
        userAttribute = { ...userAttribute, [attr]: attribute[attr] };
      }
      this.state = {
        activeTab: 0,
        isLoading: false,
        attributes: userAttribute,
        changes: {},
      };
    });
  }

  editAttribute = (field, value) => {
    let { attributes, changes } = this.state;
    attributes[field] = value;
    changes = { ...changes, [field]: true };
    this.setState({ attributes, changes });
  };

  applyChanges = async () => {
    this.setState({ isLoading: true });
    const { attributes } = this.state;

    let success = true;
    try{
      await coreApi.patchSelfAsync(attributes);
      clearSessionStorage();
      await acquireToken();
      window.location.reload();
    }catch(e){
      if(!keys.IS_PROD){
        utils.debug(e, utils.MSG_TYPE_WARN);
      }
      success = false;
    }finally{
      this.props.toggle(false);

      this.setState({ isLoading: false });

      notifyStore.addNotification({
        ...tempOptions,
        content: NotifyContent(
          success ? "success" : "danger",
          null,
          this.props.t(
            success
              ? "sidebar.changesApplied"
              : "sidebar.changesNotApplied"
          )
        ),
      });
    }
  };

  render() {
    return (
      <Modal
        backdrop={false}
        centered
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        className="modal-preferences"
        onClosed={() => this.setState({ activeTab: 0, changes: {} })}
      >
        <ModalBody className="modal-preferences-body">
          {this.state.isLoading ? <Loader /> : null}
          <div
            className="modal-preferences-close"
            onClick={() => {
              this.props.toggle(false);
            }}
          >
            {keys.ICON_CLOSE}
          </div>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={
                  classnames({ active: this.state.activeTab === 0 }) +
                  " card-point"
                }
                onClick={() => this.setState({ activeTab: 0 })}
              >
                {this.props.t("sidebar.language")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={
                  classnames({ active: this.state.activeTab === 1 }) +
                  " card-point"
                }
                onClick={() => this.setState({ activeTab: 1 })}
              >
                {this.props.t("sidebar.measureUnits")}
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId={0}>
              <UserLanguage
                t={this.props.t}
                editAttribute={this.editAttribute}
              />
            </TabPane>
            <TabPane tabId={1}>
              <UserUnits t={this.props.t} editAttribute={this.editAttribute} />
            </TabPane>
          </TabContent>
        </ModalBody>
        <ModalFooter className="modal-preferences-footer">
          <Button
            className="btn-preferences-apply"
            disabled={Object.keys(this.state.changes).length === 0}
            color="primary"
            onClick={this.applyChanges}
          >
            {this.props.t("sidebar.apply")}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default Preferences;
