import { SET_LANGUAGES } from "redux/actions";

const initialData = {
  list: [],
};

const languages = (state = initialData, action) => {
  switch (action.type) {
    case SET_LANGUAGES:
      let languages = [];

      for (let lang of action.payload.list.data.data) {
        languages.push({
          text: lang.name.split("_")[0],
          lang: lang.name.split("_")[1].split(".")[0],
        });
      }

      return { list: languages };
    default:
      return state;
  }
};

export default languages;
