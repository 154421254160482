import React from 'react';
import './style.css';

const Pagination = ({ className, currentPage, totalPages, onPageChange, visiblePages = 3 }) => {
    const getPageNumbers = () => {
        const halfVisible = Math.floor(visiblePages / 2);
        let startPage = Math.max(1, currentPage - halfVisible);
        let endPage = Math.min(totalPages, startPage + visiblePages - 1);

        // Adjust startPage if we're at the end of the range
        if (endPage - startPage + 1 < visiblePages) {
            startPage = Math.max(1, endPage - visiblePages + 1);
        }

        const pageNumbers = [];
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }
        return pageNumbers;
    };

    const toPrevPage = () => {
        if (currentPage > 1) {
            onPageChange(currentPage - 1);
        }
    }

    const toNextPage = () => {
        if (currentPage < totalPages) {
            onPageChange(currentPage + 1);
        }
    }

    return (
        <nav className={className}>
            <ul className="pagination">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link page-ball" onClick={() => toPrevPage()} disabled={currentPage === 1}>
                        {`⏴`}
                    </button>
                </li>
                {currentPage > Math.floor(visiblePages / 2) + 1 && (
                    <li className="page-item">
                        <button className="page-link page-ball" onClick={() => onPageChange(1)}>1</button>
                    </li>
                )}
                {currentPage > Math.floor(visiblePages / 2) + 2 && <li className="page-item disabled"><span className="page-link">...</span></li>}
                {getPageNumbers().map(number => (
                    <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                        <button className="page-link page-ball" onClick={() => onPageChange(number)}>
                            {number}
                        </button>
                    </li>
                ))}
                {currentPage < totalPages - Math.floor(visiblePages / 2) - 1 && <li className="page-item disabled"><span className="page-link">...</span></li>}
                {currentPage < totalPages - Math.floor(visiblePages / 2) && (
                    <li className="page-item">
                        <button className="page-link page-ball" onClick={() => onPageChange(totalPages)}>{totalPages}</button>
                    </li>
                )}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button className="page-link page-ball" onClick={() => toNextPage()} disabled={currentPage === totalPages}>
                        {`⏵`}
                    </button>
                </li>
            </ul>
        </nav>
    );
};

export default Pagination;