import React, { useRef, useEffect, useState } from "react";
import { Card, CardBody, Container, Row, Col } from "reactstrap";
import { LatLngBounds } from "leaflet";
import CardHeader from "components/Headers/CardHeader.js";
import OpenStreetMap from "components/OpenStreetMap";
import { connect } from "react-redux";
import "./dashboard.css";

const Dashboard = (props) => {
  const mapRef = useRef(null); // Ref for the map
  const [mapProps, setMapProps] = useState(null);

  useEffect(() => {
    if (props.location.site) {
      // If a site is selected, center the map on that site
      setMapProps({
        centerPosition: [
          props.location.site.latitude,
          props.location.site.longitude,
        ],
        zoom: 17,
      });
    } else {
      const newMapProps = evaluateMapProps();
      setMapProps(newMapProps);
    }
  }, [props]);

  const evaluateMapProps = () => {
    const sites = props.store.sites || []; // Ensure sites is an array

    if (!sites || sites.length === 0) {
      return {
        centerPosition: [0, 0],
        zoom: 2,
      };
    }

    const bounds = new LatLngBounds(
      sites.map((site) => [site.latitude, site.longitude])
    );

    const centerPosition = bounds.getCenter();
    let zoom = 2; // Default Zoom

    if (mapRef.current) {
      zoom = mapRef.current.getBoundsZoom(bounds);
    }

    return {
      centerPosition: [centerPosition.lat, centerPosition.lng],
      zoom: zoom,
    };
  };

  const { alerts } = props.store;
  const gatewayList = Object.keys(alerts.byId);
  let sites = JSON.parse(JSON.stringify(props.store.sites || [])); // Ensure sites is always defined

  sites.forEach((site) => {
    site.alarms = [];
    site.offlineGateways = [];
  });

  gatewayList.forEach((gatewayGuid) => {
    sites.forEach((site) => {
      site.gateways.forEach((gateway) => {
        if (gateway.gatewayGuid === gatewayGuid) {
          site.alarms.push(alerts.byId[gatewayGuid]);
        }
      });
    });
  });

  sites.forEach((site) => {
    site.gateways.forEach((gw, index) => {
      if (props.store.gateways.byId[gw.gatewayGuid] === undefined) {
        site.gateways.splice(index, 1);
      }
    });
  });

  props.store.gateways.allIds.forEach((gatewayGuid) => {
    if (props.store.gateways.byId[gatewayGuid].status === "OFF") {
      sites.forEach((site) => {
        site.gateways.forEach((gateway) => {
          if (gateway.gatewayGuid === gatewayGuid) {
            site.offlineGateways.push(props.store.gateways.byId[gatewayGuid]);
          }
        });
      });
    }
  });

  return (
    <>
      <Container fluid className="mt--9">
        <CardHeader />
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardBody className="card-leaflet-dashboard">
                {mapProps && mapProps.centerPosition && mapProps.zoom ? (
                  <OpenStreetMap
                    ref={mapRef}
                    markers={sites}
                    center={mapProps.centerPosition}
                    zoom={mapProps.zoom}
                  />
                ) : (
                  <p>Loading map...</p>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    store: {
      sitesLoaded: state.sitesLoaded,
      sites: Object.values(state.sites.byId),
      alerts: state.alerts,
      gateways: state.gateways,
    },
  };
};

export default connect(mapStateToProps)(Dashboard);
