import React from "react";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  InputGroupText,
  InputGroup,
  Alert,
} from "reactstrap";

import Loader from "components/Loader";

import AdminDtcFileTable from "components/admin/AdminDtcFileTable";

import api from "services/backendService";
import keys from "configs/constants";
import { withTranslation } from "react-i18next";
class DeviceDescriptors extends React.Component {
  state = {
    model: "",
    family: "",
    version: "",
    eeprom: "",
    dtcFile: "",
    drcFile: "",
    welFile: "",
    welTFile: "",
    dtcFilesList: [],
    showLoader: false,
  };

  alertToggle() {
    this.setState({ alert: null });
  }

  async refreshData() {
    this.setState({ showLoader: true });
    const dscFiles = await api.getDeviceDSCList();
    this.setState({ dtcFilesList: dscFiles, showLoader: false });
  }

  async onDeleteDSCFile(data) {
    if (data.welT !== null)
      await api.deleteDeviceWelTranslationFile(data.welT, true);
    if (data.wel !== null) await api.deleteDeviceWelFile(data.wel, true);
    if (data.drc !== null)
      await api.deleteDeviceRuntimeCommandFile(data.drc, true);
    await api.deleteDeviceTypeFile(data.dtc, true);
    await this.refreshData();
  }

  async componentDidMount() {
    await this.refreshData();
  }

  async onUpdateButtonClicked(evt) {
    evt.preventDefault();
    if (this.state.model === "") {
      this.setState({
        alert: this.props.t("deviceDescriptor.missingModel") + "!",
      });
      return;
    }
    if (this.state.family === "") {
      this.setState({
        alert: this.props.t("deviceDescriptor.missingFamily") + "!",
      });
      return;
    }
    if (this.state.version === "") {
      this.setState({
        alert: this.props.t("deviceDescriptor.missingVersion") + "!",
      });
      return;
    }
    if (this.state.eeprom === "") {
      this.setState({
        alert: this.props.t("deviceDescriptor.missingE2") + "!",
      });
      return;
    }
    const mfve =
      this.state.model +
      "_" +
      this.state.family +
      "-" +
      this.state.version +
      "-" +
      this.state.eeprom;

    if (this.state.dtcFile.length === 1 || this.state.drcFile.length === 1)
      this.setState({ showLoader: true });
    if (this.state.dtcFile.length === 1)
      await api.addUpdateDeviceTypeFile(mfve, this.state.dtcFile[0]); // Device Type File
    if (this.state.drcFile.length === 1)
      await api.addUpdateDeviceRuntimeCommandFile(mfve, this.state.drcFile[0]); // Device Runtime Command
    if (this.state.welFile.length === 1)
      await api.addUpdateDeviceWelFile(mfve, this.state.welFile[0]); // Device Wel File
    if (this.state.welTFile.length === 1) {
      const r = await api.addUpdateDeviceWelTranslationFile(
        mfve,
        this.state.welTFile[0]
      ); // Device Wel Translation file
      if (r === null) window.alert(this.props.t("deviceDescriptor.missingWEL"));
    }

    await this.refreshData();
  }

  onInputChange(event) {
    const target = event.target;
    const value = target.type === "file" ? target.files : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  }

  render() {
    const alertMessage = this.state.alert;
    if (alertMessage) {
      return (
        <div>
          <Alert
            color="warning"
            fade={false}
            toggle={this.alertToggle.bind(this)}
          >
            {alertMessage}
          </Alert>
        </div>
      );
    }

    if (this.state.showLoader) return <Loader />;
    return (
      <>
        {/* Page content  */}
        <Container fluid className="mt--9">
          {/* <CardHeader /> */}
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardBody>
                  <Form
                    className="mb-4"
                    name="dtcfileupdate"
                    onSubmit={this.onUpdateButtonClicked.bind(this)}
                  >
                    <FormGroup>
                      <InputGroup>
                        <InputGroupText>{keys.ICON_SITE}</InputGroupText>
                        <Input
                          id="model"
                          name="model"
                          placeholder={this.props.t(
                            "deviceDescriptor.enterModel"
                          )}
                          type="text"
                          onChange={this.onInputChange.bind(this)}
                          value={this.state.model}
                        />
                        <InputGroupText>{keys.ICON_SITE}</InputGroupText>
                        <Input
                          name="family"
                          placeholder={this.props.t(
                            "deviceDescriptor.enterFamily"
                          )}
                          type="text"
                          onChange={this.onInputChange.bind(this)}
                          value={this.state.family}
                        />
                        <InputGroupText>{keys.ICON_SITE}</InputGroupText>
                        <Input
                          name="version"
                          placeholder={this.props.t(
                            "deviceDescriptor.enterVersion"
                          )}
                          type="text"
                          onChange={this.onInputChange.bind(this)}
                          value={this.state.version}
                        />
                        <InputGroupText>{keys.ICON_SITE}</InputGroupText>
                        <Input
                          name="eeprom"
                          placeholder={this.props.t(
                            "deviceDescriptor.enterEEVersion"
                          )}
                          type="text"
                          onChange={this.onInputChange.bind(this)}
                          value={this.state.eeprom}
                        />
                      </InputGroup>
                      <InputGroup>
                        <div className="d-flex mt-2 btn border border-info">
                          <span className="mt-3">.json file</span>
                          <Input
                            className="btn"
                            name="dtcFile"
                            placeholder="select dtc file..."
                            type="file"
                            accept=".json"
                            onChange={this.onInputChange.bind(this)}
                          />
                        </div>
                      </InputGroup>
                      <InputGroup>
                        <div className="d-flex mt-2 btn border border-info">
                          <span className="mt-3"> .dvc file </span>
                          <Input
                            className="btn"
                            name="drcFile"
                            placeholder="select drc file..."
                            type="file"
                            accept=".dvc"
                            onChange={this.onInputChange.bind(this)}
                          />
                        </div>
                      </InputGroup>
                      <InputGroup>
                        <div className="d-flex mt-2 btn border border-info">
                          <span className="mt-3"> .wel file </span>
                          <Input
                            className="btn"
                            name="welFile"
                            placeholder="select wel file..."
                            type="file"
                            accept=".wel"
                            onChange={this.onInputChange.bind(this)}
                          />
                        </div>
                      </InputGroup>
                      <InputGroup>
                        <div className="d-flex mt-2 btn border border-info">
                          <span className="mt-3"> .en-* file </span>
                          <Input
                            className="btn"
                            name="welTFile"
                            placeholder="select wel translation file..."
                            type="file"
                            accept=".en-GB,.en-US"
                            onChange={this.onInputChange.bind(this)}
                          />
                        </div>
                      </InputGroup>
                    </FormGroup>
                    <Button className="mb-2" type="submit">
                      {this.props.t("deviceDescriptor.addUpdateDDF")}
                    </Button>
                  </Form>
                  <AdminDtcFileTable
                    data={this.state.dtcFilesList}
                    deleteDSCFile={this.onDeleteDSCFile.bind(this)}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withTranslation("common")(DeviceDescriptors);
