import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  CardHeader,
  CardBody,
  Table,
  Collapse,
  Button,
  Modal,
  ModalBody,
} from "reactstrap";
import Loader from "../../components/Loader";
import api from "../../services/backendService";
import utils from "../../utils";
import keys from "../../configs/constants";
import "./deletedEntities.css";
import { withTranslation } from "react-i18next";

const entities = ["customers", "sites", "gateways", "controllers", "users"];

class DeletedEntities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entity: "selectEntity",
      isEntityOpen: false,
      deleted: true,
      customers: [],
      sites: [],
      gateways: [],
      devices: [],
      users: [],
      totalEntities: 0,
      isLoading: false,
    };
  }

  setEntity = async (e, force = false) => {
    if (this.state.entity === e && force === false) {
      this.setState({ isLoading: false });
      return;
    }

    let data;

    switch (e) {
      case entities[0]:
        data = await api.getCustomersFull();
        if (data.status === 200) {
          let customers = data.data.data.filter((c) => c.deletedAt !== null);
          this.setState({
            customers,
            totalEntities: customers.length,
            sites: [],
            gateways: [],
            devices: [],
            users: [],
          });
        }
        break;
      case entities[1]:
        data = await api.getSitesFull();
        if (data.status === 200) {
          let sites = data.data.data.filter((s) => s.deletedAt !== null);

          this.setState({
            customers: [],
            sites,
            totalEntities: sites.length,
            gateways: [],
            devices: [],
            users: [],
          });
        }
        break;
      case entities[2]:
        data = await api.getGatewaysFull();
        if (data.status === 200) {
          let gateways = data.data.data.filter((g) => g.deletedAt !== null);

          this.setState({
            customers: [],
            sites: [],
            gateways,
            totalEntities: gateways.length,
            devices: [],
            users: [],
          });
        }
        break;
      case entities[3]:
        data = await api.getDevicesFull();

        if (data.status === 200) {
          let devices = data.data.data.filter((d) => d.deletedAt !== null);

          for (let device of devices) {
            let thresholds = await api.getAllThresholdDetailsFull(
              device.deviceGuid,
              null,
              true
            );
            if (thresholds.status === 200) {
              device.thresholds = thresholds.data.data.filter(
                (th) => th.deletedAt === null
              );
            }
          }

          this.setState({
            customers: [],
            sites: [],
            gateways: [],
            devices,
            totalEntities: devices.length,
            users: [],
          });
        }
        break;
      case entities[4]:
        data = await api.getUsersDetails(null, true);
        if (data.status === 200) {
          let users = data.data.data.filter((u) => u.deletedAt !== null);

          this.setState({
            customers: [],
            sites: [],
            gateways: [],
            devices: [],
            users,
            totalEntities: users.length,
          });
        }
        break;
      default:
        break;
    }

    this.setState({ entity: e, isLoading: false });
  };

  removeThreshold = (deviceGuid, thresholdGuid) => {
    let { devices } = this.state;
    let devIndex = -1;
    let thresholdIndex = -1;

    for (let i = 0; i < devices.length; i++) {
      if (devices[i].deviceGuid === deviceGuid) {
        devIndex = i;
        break;
      }
    }

    if (devIndex !== -1) {
      for (let i = devices[devIndex].thresholds.length - 1; i >= 0; i--) {
        if (devices[devIndex].thresholds[i].thresholdGuid === thresholdGuid) {
          thresholdIndex = i;
          break;
        }
      }
    }

    if (thresholdIndex !== -1) {
      devices[devIndex].thresholds.splice(thresholdIndex, 1);
    }

    this.setState({ devices });
  };

  render() {
    return (
      <Card className="mt--9 mx-5">
        <CardBody>
          <Dropdown
            isOpen={this.state.isEntityOpen}
            toggle={() =>
              this.setState({ isEntityOpen: !this.state.isEntityOpen })
            }
          >
            <DropdownToggle caret>
              {this.props.t("deletedEntities." + this.state.entity) +
                (this.state.entity === "selectEntity" ? "..." : "")}
            </DropdownToggle>
            <DropdownMenu>
              {entities.map((e, i) => {
                return (
                  <DropdownItem
                    key={i}
                    onClick={async () => {
                      this.setState({ isLoading: true });
                      await this.setEntity(e);
                    }}
                  >
                    {this.props.t("deletedEntities." + e)}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </Dropdown>
          {this.state.isLoading ? <Loader /> : null}
          <div
            className={`mt-4 ml-5 ${
              this.state.totalEntities > 0 ? "" : "d-none"
            }`}
          >
            {this.props.t("deletedEntities.total") +
              ": " +
              this.state.totalEntities}
          </div>
          <div
            className={`entity-container${
              this.state.totalEntities === 0 ? "-none" : ""
            }`}
          >
            {this.state.customers.map((customer, i) => {
              return (
                <CustomerContainer
                  key={i}
                  customer={customer}
                  updateCustomers={async () => {
                    this.setState({ isLoading: true });
                    await this.setEntity(entities[0], true);
                  }}
                  t={this.props.t}
                />
              );
            })}
            {this.state.sites.map((site, i) => {
              return (
                <SiteContainer
                  key={i}
                  site={site}
                  updateSites={async () => {
                    this.setState({ isLoading: true });
                    await this.setEntity(entities[1], true);
                  }}
                  t={this.props.t}
                />
              );
            })}
            {this.state.gateways.map((gateway, i) => {
              return (
                <GatewayContainer
                  key={i}
                  gateway={gateway}
                  updateGateways={async () => {
                    this.setState({ isLoading: true });
                    await this.setEntity(entities[2], true);
                  }}
                  t={this.props.t}
                />
              );
            })}
            {this.state.devices.map((device, i) => {
              return (
                <DeviceContainer
                  key={i}
                  device={device}
                  updateDevices={async () => {
                    this.setState({ isLoading: true });
                    await this.setEntity(entities[3], true);
                  }}
                  removeThreshold={this.removeThreshold}
                  t={this.props.t}
                />
              );
            })}
            {this.state.users.map((user, i) => {
              return (
                <UserContainer
                  key={i}
                  user={user}
                  updateUsers={async () => {
                    this.setState({ isLoading: true });
                    await this.setEntity(entities[4], true);
                  }}
                  t={this.props.t}
                />
              );
            })}
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default withTranslation("common")(DeletedEntities);

class ConfirmModal extends Component {
  render() {
    return (
      <Modal
        isOpen={this.props.isModalOpen}
        toggle={this.props.toggleModalConfirmDelete}
        centered
      >
        <ModalBody className="bg-light m-2">
          <div>
            <h3>{this.props.t("deletedEntities.confirmDeleteText")}</h3>
          </div>
          <div className="w-100 mt-4">
            <Button
              className="float-right m-1 w-25"
              onClick={this.props.toggleModalConfirmDelete}
            >
              {this.props.t("deletedEntities.no")}
            </Button>
            <Button
              className="float-right m-1 w-25"
              onClick={async () => {
                await this.props.deleteEntity();
              }}
            >
              {this.props.t("deletedEntities.yes")}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

class CustomerContainer extends Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false, isModalOpen: false };
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  toggleModalConfirmDelete = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  deleteCustomer = async () => {
    const { customer } = this.props;
    await api.deleteCustomer(customer.customerGuid, true);

    this.toggleModalConfirmDelete();
    this.props.updateCustomers();
  };

  render() {
    const { customer } = this.props;
    return (
      <>
        <ConfirmModal
          deleteEntity={this.deleteCustomer}
          toggleModalConfirmDelete={this.toggleModalConfirmDelete}
          isModalOpen={this.state.isModalOpen}
          t={this.props.t}
        />
        <Card className="m-5">
          <CardHeader className="bg-primary">
            <h2 className="text-center text-white">{customer.name}</h2>
          </CardHeader>
          <CardBody>
            <table className="m-4">
              <tbody>
                <tr>
                  <th>{this.props.t("deletedEntities.customerId") + ": "}</th>
                  <td>{customer.customerGuid}</td>
                </tr>
                <tr>
                  <th>{this.props.t("deletedEntities.createdAt") + ": "}</th>
                  <td>{new Date(customer.createdAt + "Z").toLocaleString()}</td>
                </tr>
                <tr>
                  <th>{this.props.t("deletedEntities.modifiedAt") + ": "}</th>
                  <td>
                    {new Date(customer.modifiedAt + "Z").toLocaleString()}
                  </td>
                </tr>
                <tr>
                  <th>{this.props.t("deletedEntities.deletedAt") + ": "}</th>
                  <td>{new Date(customer.deletedAt + "Z").toLocaleString()}</td>
                </tr>
              </tbody>
            </table>
            <Button
              className="w-100"
              color={
                customer.sites === undefined || customer.sites.length === 0
                  ? "info"
                  : "warning"
              }
              onClick={this.toggle}
            >
              {this.props.t("deletedEntities.sites") +
                " (" +
                (customer.sites === undefined ? "0" : customer.sites.length) +
                ")"}
            </Button>
            <Collapse isOpen={this.state.isOpen} className="mb-4">
              <Table>
                <thead>
                  <tr>
                    <th>{this.props.t("deletedEntities.name")}</th>
                    <th>{this.props.t("deletedEntities.siteId")}</th>
                  </tr>
                </thead>
                <tbody>
                  {customer.sites.map((site, j) => {
                    return (
                      <tr key={j}>
                        <td>{site.name}</td>
                        <td>{site.siteGuid}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Collapse>
            <div className="w-100 mt-5">
              <Button
                color="danger"
                className="float-right"
                onClick={this.toggleModalConfirmDelete}
              >
                {this.props.t("deletedEntities.delete")}
              </Button>
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}

class SiteContainer extends Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false, isModalOpen: false };
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  toggleModalConfirmDelete = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  deleteSite = async () => {
    const { site } = this.props;
    await api.deleteSite(site.siteGuid, true);

    this.toggleModalConfirmDelete();
    this.props.updateSites();
  };

  render() {
    const { site } = this.props;
    return (
      <>
        <ConfirmModal
          deleteEntity={this.deleteSite}
          toggleModalConfirmDelete={this.toggleModalConfirmDelete}
          isModalOpen={this.state.isModalOpen}
          t={this.props.t}
        />
        <Card className="m-5">
          <CardHeader className="bg-primary">
            <h2 className="text-center text-white">{site.name}</h2>
          </CardHeader>
          <CardBody>
            <table className="m-4">
              <tbody>
                <tr>
                  <th>{this.props.t("deletedEntities.country") + ": "}</th>
                  <td>{site.country}</td>
                </tr>
                <tr>
                  <th>{this.props.t("deletedEntities.city") + ": "}</th>
                  <td>{site.city}</td>
                </tr>
                <tr>
                  <th>{this.props.t("deletedEntities.coordinates") + ": "}</th>
                  <td>
                    <span>
                      {this.props.t("deletedEntities.latitude") + ": "}
                    </span>
                    <span>{site.latitude}</span>
                    <br />
                    <span>
                      {this.props.t("deletedEntities.longitude") + ": "}
                    </span>
                    <span>{site.longitude}</span>
                  </td>
                </tr>
                <tr>
                  <th>{this.props.t("deletedEntities.siteId") + ": "}</th>
                  <td>{site.siteGuid}</td>
                </tr>
                <tr>
                  <th>{this.props.t("deletedEntities.customerName") + ": "}</th>
                  <td>{site.customer.name}</td>
                </tr>
                <tr>
                  <th>{this.props.t("deletedEntities.createdAt") + ": "}</th>
                  <td>{new Date(site.createdAt + "Z").toLocaleString()}</td>
                </tr>
                <tr>
                  <th>{this.props.t("deletedEntities.modifiedAt") + ": "}</th>
                  <td>{new Date(site.modifiedAt + "Z").toLocaleString()}</td>
                </tr>
                <tr>
                  <th>{this.props.t("deletedEntities.deletedAt") + ": "}</th>
                  <td>{new Date(site.deletedAt + "Z").toLocaleString()}</td>
                </tr>
              </tbody>
            </table>
            <Button
              className="w-100"
              color={
                site.gateways === undefined || site.gateways.length === 0
                  ? "info"
                  : "warning"
              }
              onClick={this.toggle}
            >
              {this.props.t("deletedEntities.gateways") +
                " (" +
                (site.gateways === undefined ? "0" : site.gateways.length) +
                ")"}
            </Button>
            <Collapse isOpen={this.state.isOpen} className="mb-4">
              <Table>
                <thead>
                  <tr>
                    <th>{this.props.t("deletedEntities.name")}</th>
                    <th>{this.props.t("deletedEntities.gatewayId")}</th>
                  </tr>
                </thead>
                <tbody>
                  {site.gateways.map((gateway, j) => {
                    return (
                      <tr key={j}>
                        <td>{gateway.name}</td>
                        <td>{gateway.macAddress}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Collapse>
            <div className="w-100 mt-5">
              <Button
                color="danger"
                className="float-right"
                onClick={this.toggleModalConfirmDelete}
              >
                {this.props.t("deletedEntities.delete")}
              </Button>
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}

class GatewayContainer extends Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false, isModalOpen: false };
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  toggleModalConfirmDelete = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  deleteGateway = async () => {
    const { gateway } = this.props;
    await api.deleteGateway(gateway.gatewayGuid, true);

    this.toggleModalConfirmDelete();
    this.props.updateGateways();
  };

  render() {
    const { gateway } = this.props;

    return (
      <>
        <ConfirmModal
          deleteEntity={this.deleteGateway}
          toggleModalConfirmDelete={this.toggleModalConfirmDelete}
          isModalOpen={this.state.isModalOpen}
          t={this.props.t}
        />
        <Card className="m-5">
          <CardHeader className="bg-primary">
            <h2 className="text-center text-white">{gateway.name}</h2>
          </CardHeader>
          <CardBody>
            <table className="m-4">
              <tbody>
                <tr>
                  <th>{this.props.t("deletedEntities.macAddress") + ": "}</th>
                  <td>{gateway.macAddress}</td>
                </tr>
                <tr>
                  <th>{this.props.t("deletedEntities.releaseGw") + ": "}</th>
                  <td>{gateway.releaseFW}</td>
                </tr>
                <tr>
                  <th>{this.props.t("deletedEntities.pushInterval") + ": "}</th>
                  <td>{gateway.telemetryPushInterval}</td>
                </tr>
                <tr>
                  <th>{this.props.t("deletedEntities.gatewayId") + ": "}</th>
                  <td>{gateway.gatewayGuid}</td>
                </tr>
                <tr>
                  <th>{this.props.t("deletedEntities.siteName") + ": "}</th>
                  <td>{gateway.site.name}</td>
                </tr>
                <tr>
                  <th>{this.props.t("deletedEntities.customerName") + ": "}</th>
                  <td>{gateway.customer.name}</td>
                </tr>
                <tr>
                  <th>{this.props.t("deletedEntities.createdAt") + ": "}</th>
                  <td>{new Date(gateway.createdAt + "Z").toLocaleString()}</td>
                </tr>
                <tr>
                  <th>{this.props.t("deletedEntities.modifiedAt") + ": "}</th>
                  <td>{new Date(gateway.modifiedAt + "Z").toLocaleString()}</td>
                </tr>
                <tr>
                  <th>{this.props.t("deletedEntities.deletedAt") + ": "}</th>
                  <td>{new Date(gateway.deletedAt + "Z").toLocaleString()}</td>
                </tr>
              </tbody>
            </table>
            <Button
              className="w-100"
              color={
                gateway.devices === undefined || gateway.devices.length === 0
                  ? "info"
                  : "warning"
              }
              onClick={this.toggle}
            >
              {this.props.t("deletedEntities.controllers") +
                " (" +
                (gateway.devices === undefined ? "0" : gateway.devices.length) +
                ")"}
            </Button>
            <Collapse isOpen={this.state.isOpen} className="mb-4">
              <Table>
                <thead>
                  <tr>
                    <th>{this.props.t("deletedEntities.model")}</th>
                    <th>{this.props.t("deletedEntities.friendlyName")}</th>
                    <th>{this.props.t("deletedEntities.deviceId")}</th>
                  </tr>
                </thead>
                <tbody>
                  {gateway.devices.map((device, j) => {
                    return (
                      <tr key={j}>
                        <td>{device.model}</td>
                        <td>{device.friendlyName}</td>
                        <td>{device.deviceGuid}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Collapse>
            <div className="w-100 mt-5">
              <Button
                color="danger"
                className="float-right"
                onClick={this.toggleModalConfirmDelete}
              >
                {this.props.t("deletedEntities.delete")}
              </Button>
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}

class DeviceContainer extends Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false };
  }

  render() {
    const { device } = this.props;

    const model = utils.getModelFromMFVE(device.model);
    let anag = [];

    if (device.model.split("_")[1] !== undefined)
      anag = device.model.split("_")[1].split("-");

    return (
      <Card className="m-5">
        <CardHeader className="bg-primary">
          <h2 className="text-center text-white">
            {model}
            <span className="deleted-reference">
              {this.props.t("deletedEntities.reference") +
                ": " +
                device.address}
            </span>
          </h2>
          <h3 className="text-center text-white">
            {device.friendlyName || "- - -"}
          </h3>
        </CardHeader>
        <CardBody>
          <table>
            <tbody>
              <tr>
                <th className="w-25">
                  {this.props.t("deletedEntities.customer") + ": "}
                </th>
                <td>{device.customer.name}</td>
              </tr>
              <tr>
                <th className="w-25">
                  {this.props.t("deletedEntities.site") + ": "}
                </th>
                <td>{device.site.name}</td>
              </tr>
              <tr>
                <th className="w-25">
                  {this.props.t("deletedEntities.gateway") + ": "}
                </th>
                <td>{device.gateway.name}</td>
              </tr>
            </tbody>
          </table>
          <span className="delete-anag">
            {anag.map((a, i) => (
              <span key={i}>
                <span className="font-weight-bold ml-3">
                  {i === 0
                    ? this.props.t("deletedEntities.family") + ": "
                    : i === 1
                    ? this.props.t("deletedEntities.release") + ": "
                    : this.props.t("deletedEntities.mapLayout") + ": "}
                </span>
                <span className="mr-3">
                  {parseInt(a, 16) / (i === 1 ? 10 : 1)}
                </span>
              </span>
            ))}
          </span>
          <span className="float-right mb-2">
            <span className="font-weight-bold">
              {this.props.t("deletedEntities.deletedAt") + ": "}
            </span>
            <span>{new Date(device.deletedAt + "Z").toLocaleString()}</span>
          </span>
          <h3
            className={`rounded mt-4 p-2 text-center ${
              device.thresholds.length > 0 ? "bg-warning" : "bg-info"
            } text-white deleted-thresholds`}
            onClick={() => {
              this.setState({ isOpen: !this.state.isOpen });
            }}
          >
            {this.props.t("deletedEntities.thresholds") +
              " (" +
              device.thresholds.length +
              ")"}
          </h3>
          <Collapse isOpen={this.state.isOpen}>
            <Button
              className="mt-2"
              onClick={async () => {
                for (let th of device.thresholds) {
                  await api.deleteThreshold(th.thresholdGuid);
                }

                await this.props.updateDevices();
              }}
            >
              {this.props.t("deletedEntities.deleteAll")}
            </Button>
            <Table className="mt-4 w-25">
              <thead>
                <tr>
                  <th>{this.props.t("deletedEntities.label")}</th>
                  <th>{this.props.t("deletedEntities.delete")}</th>
                </tr>
              </thead>
              <tbody>
                {device.thresholds.map((th, i) => {
                  return (
                    <ThresholdRecord
                      key={i}
                      th={th}
                      deviceGuid={device.deviceGuid}
                      removeThreshold={this.props.removeThreshold}
                      t={this.props.t}
                    />
                  );
                })}
              </tbody>
            </Table>
          </Collapse>
        </CardBody>
      </Card>
    );
  }
}

class UserContainer extends Component {
  constructor(props) {
    super(props);

    this.state = { isModalOpen: false };
  }

  toggleModalConfirmDelete = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  deleteUser = async () => {
    const { user } = this.props;
    await api.deleteUser(user.userGuid, true);

    this.toggleModalConfirmDelete();
    this.props.updateUsers();
  };

  render() {
    const { user } = this.props;

    return (
      <>
        <ConfirmModal
          deleteEntity={this.deleteUser}
          toggleModalConfirmDelete={this.toggleModalConfirmDelete}
          isModalOpen={this.state.isModalOpen}
          t={this.props.t}
        />
        <Card className="m-5">
          <CardHeader className="bg-primary">
            <h2 className="text-center text-white">
              {user.firstName === "" && user.lastName === ""
                ? "---"
                : user.firstName + " " + user.lastName}
            </h2>
            <h4 className="text-center text-white">
              {user.username === "" ? "---" : user.username}
            </h4>
          </CardHeader>
          <CardBody>
            <table>
              <tbody>
                <tr>
                  <th>{this.props.t("deletedEntities.firstName") + ": "}</th>
                  <td>{user.firstName}</td>
                </tr>
                <tr>
                  <th>{this.props.t("deletedEntities.lastName") + ": "}</th>
                  <td>{user.lastName}</td>
                </tr>
                <tr>
                  <th>{this.props.t("deletedEntities.username") + ": "}</th>
                  <td>{user.username}</td>
                </tr>
                <tr>
                  <th>{this.props.t("deletedEntities.email") + ": "}</th>
                  <td>{user.email}</td>
                </tr>
                <tr>
                  <th>{this.props.t("deletedEntities.role") + ": "}</th>
                  <td>{user.role}</td>
                </tr>
                <tr>
                  <th>{this.props.t("deletedEntities.userId") + ": "}</th>
                  <td>{user.userGuid}</td>
                </tr>
                <tr>
                  <th>{this.props.t("deletedEntities.createdAt") + ": "}</th>
                  <td>{new Date(user.createdAt + "Z").toLocaleString()}</td>
                </tr>
                <tr>
                  <th>{this.props.t("deletedEntities.modifiedAt") + ": "}</th>
                  <td>{new Date(user.modified + "Z").toLocaleString()}</td>
                </tr>
                <tr>
                  <th>{this.props.t("deletedEntities.lastLoginAt") + ": "}</th>
                  <td>{new Date(user.lastLoginAt + "Z").toLocaleString()}</td>
                </tr>
                <tr>
                  <th>{this.props.t("deletedEntities.deletedAt") + ": "}</th>
                  <td>{new Date(user.deletedAt + "Z").toLocaleString()}</td>
                </tr>
              </tbody>
            </table>
            {/* <div className="w-100 mt-2">
              <Button
                color="danger"
                className="float-right"
                onClick={this.toggleModalConfirmDelete}
              >
                {this.props.t("deletedEntities.delete")}
              </Button>
            </div> */}
          </CardBody>
        </Card>
      </>
    );
  }
}

class ThresholdRecord extends Component {
  constructor(props) {
    super(props);
    this.state = { isDeleting: false };
  }

  render() {
    let { th } = this.props;
    return (
      <tr>
        <td>{th.label}</td>
        <td>
          {this.state.isDeleting ? (
            <Loader />
          ) : (
            <span
              className="deleted-devices-trash"
              onClick={async () => {
                this.setState({ isDeleting: true });
                await api.deleteThreshold(th.thresholdGuid);
                this.setState({ isDeleting: false });
                this.props.removeThreshold(
                  this.props.deviceGuid,
                  th.thresholdGuid
                );
              }}
            >
              {keys.ICON_TRASH}
            </span>
          )}
        </td>
      </tr>
    );
  }
}
