import SiteProperties from "components/Forms/SiteProperties";
import React from "react";

// reactstrap components
import { Container, Col, Row } from "reactstrap";

class EditSiteProperties extends React.Component {
  state = {};

  render() {
    const siteInfo = this.props.location.site;
    if (!siteInfo) {
      return "";
    }
    return (
      <>
        {/* Page content */}
        <Container fluid className="mt--9">
          <Row>
            <Col>
              <SiteProperties {...this.props} />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default EditSiteProperties;
