import React from "react";
import { PuffLoader } from "react-spinners";

class Loader extends React.Component {
  render() {
    const style = {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      zIndex: "1000",
    };

    if (this.props.local) {
      return (
        <div className="justify-content-between text-center">
          <PuffLoader color="#be97e8" size={this.props.size} />
        </div>
      );
    } else if (this.props.custom) {
      return (
        <div className={this.props.className}>
          <PuffLoader
            color={
              this.props.color !== undefined ? this.props.color : "#be97e8"
            }
            size={this.props.size}
          />
        </div>
      );
    } else {
      return (
        <div style={style}>
          <PuffLoader color="#be97e8" size={100} />
        </div>
      );
    }
  }
}

export default Loader;
