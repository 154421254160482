import React, { useState, useMemo } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  Input,
  InputGroup,
} from "reactstrap";
import keys from "configs/constants";
import { withTranslation } from "react-i18next";
import "./style.css";

const SelectModelModal = (props) => {
  const { isOpen, data, onConfirm, onClose, t } = props;
  const [filter, setFilter] = useState("");

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const filteredData = useMemo(() => {
    return data.filter((item) =>
      item.model.toLowerCase().includes(filter.toLowerCase())
    );
  }, [data, filter]);

  const handleConfirm = (mfve) => {
    onConfirm(mfve);
    handleClose();
  };

  const handleClose = () => {
    setFilter("");
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={handleClose}
      className="modal-dialog-centered modal-dialog-scrollable modal-lg"
    >
      <ModalHeader>
        <div className="h2 modal-title">
          {t("gatewayProperties.selectModel")}
        </div>
      </ModalHeader>
      <ModalBody>
        <InputGroup className="mb-4">
          <div addonType="prepend">
            <span className="input-group-text">{keys.ICON_SEARCH}</span>
          </div>
          <Input
            type="text"
            placeholder={t("gatewayProperties.model")}
            value={filter}
            onChange={handleFilterChange}
          />
        </InputGroup>
        <div
          className="table-responsive select-model-modal-table"
        >
          <Table responsive hover className="border mb-0 ">
            <thead className="thead-light">
              <tr>
                <th scope="col">{t("gatewayProperties.model")}</th>
                <th scope="col">{t("gatewayProperties.family")}</th>
                <th scope="col">{t("gatewayProperties.release")}</th>
                <th scope="col">{t("gatewayProperties.mapLayout")}</th>
                <th scope="col" />
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => (
                <tr key={index}>
                  <td className="align-middle">{item.model}</td>
                  <td className="align-middle">{item.family}</td>
                  <td className="align-middle">{item.firmwareRelease}</td>
                  <td className="align-middle">{item.mapLayout}</td>
                  <td>
                    <Button
                      size="sm"
                      className="ml-xs-2 btn-icon"
                      color="primary"
                      type="button"
                      onClick={() => handleConfirm(item.mfve)}
                    >
                      <span className="btn-inner--icon">{keys.ICON_SAVE}</span>
                      <span className="btn-inner--text">
                        {t("generic.apply")}
                      </span>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>{" "}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default withTranslation("common")(SelectModelModal);
