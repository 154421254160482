import React, { useState } from "react";

import { Tooltip } from "reactstrap";

export const TooltipButton = (props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const position = props.position === undefined ? "top" : props.position;
  const delay =
    props.delay === undefined ? { show: 400, hide: 0 } : props.delay;

  return (
    <Tooltip
      placement={position}
      isOpen={tooltipOpen}
      target={props.target}
      toggle={toggle}
      delay={delay}
      style={{
        fontSize: 12,
        background: "white",
        color: "#101060",
        ...props.style,
      }}
      hideArrow={props.hideArrow || false}
    >
      {props.text}
    </Tooltip>
  );
};

export const TooltipWarning = (props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => {
    if (props.warning) setTooltipOpen(true);
    else setTooltipOpen(false);
  };

  const position = props.position === undefined ? "top" : props.position;

  return (
    <Tooltip
      trigger="hover"
      placement={position}
      isOpen={tooltipOpen}
      target={props.target}
      toggle={toggle}
      delay={{ show: 0, hide: 0 }}
      style={{ fontSize: 12, background: "white", color: "#601010" }}
    >
      {props.text}
    </Tooltip>
  );
};
