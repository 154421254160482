import React, { Component } from "react";
import icons from "../../configs/constants";
import utils from "../../utils";
import { LicenseInfo } from "./LicenseInfo";
import { Button, Card, CardBody, Modal, ModalBody, Table } from "reactstrap";
import { LicenseListFilters } from "./LicenseListFilters";

const tableColumns = [
  "license.type",
  "license.state",
  "license.duration",
  "license.activationDate",
  "license.expirationDate",
  "license.timeLeft",
  "license.gateway",
];

export class LicenseList extends Component {
  constructor(props) {
    super(props);

    let siteList = {};

    Object.keys(this.props.sites).forEach((siteGuid) => {
      const site = this.props.sites[siteGuid];
      const { gateways } = site;

      let gatewayList = {
        ["site" + siteGuid]: {
          text: site.name,
          header: true,
          disabled: true,
        },
      };

      Object.keys(gateways).forEach((gatewayGuid) => {
        const gw = gateways[gatewayGuid];

        gatewayList = {
          ...gatewayList,
          [gatewayGuid]: {
            text: gw.name,
            checkbox: true,
            checkboxStatus: false,
            command: () => {
              let { siteList } = this.state;
              siteList[siteGuid].gateways[gatewayGuid].checkboxStatus =
                !siteList[siteGuid].gateways[gatewayGuid].checkboxStatus;
              this.setState({ siteList });
            },
            hidden: false,
          },
        };
      });

      siteList = {
        ...siteList,
        [siteGuid]: {
          text: site.name,
          checkbox: true,
          checkboxStatus: false,
          command: () => {
            let { siteList, licenses } = this.state;
            siteList[siteGuid].checkboxStatus =
              !siteList[siteGuid].checkboxStatus;

            siteList = this.updateGatewayList(siteList);

            this.setState({ siteList, licenses });
          },
          gateways: gatewayList,
        },
      };
    });

    const toggleFilter = (i) => {
      let { filtersItems } = this.state;
      filtersItems[i].checkboxStatus = !filtersItems[i].checkboxStatus;
      this.setState({ filtersItems });
    };

    let tableIconSort = {};

    tableColumns.forEach((k) => {
      tableIconSort = { ...tableIconSort, [k]: 0 };
    });

    this.state = {
      tableIconSort,
      siteList,
      licenses: this.props.licenses.map((l) => {
        return { ...l, hidden: false };
      }),
      filtersItems: [
        {
          text: this.props.t("license.activeLicenses"),
          checkbox: true,
          checkboxStatus: false,
          command: () => {
            toggleFilter(0);
          },
        },
        {
          text: this.props.t("license.expiredLicenses"),
          checkbox: true,
          checkboxStatus: false,
          command: () => {
            toggleFilter(1);
          },
        },
        {
          text: this.props.t("license.expiringLicense"),
          checkbox: true,
          checkboxStatus: false,
          command: () => {
            toggleFilter(2);
          },
        },
        {
          text: this.props.t("license.nextLicenses"),
          checkbox: true,
          checkboxStatus: false,
          command: () => {
            toggleFilter(3);
          },
        },
        {
          text: this.props.t("license.notAssignedLicenses"),
          checkbox: true,
          checkboxStatus: false,
          command: () => {
            toggleFilter(4);
          },
        },
      ],
      showPropertiesModal: false,
      showLicenseLegend: false,
    };
  }

  updateGatewayList = (siteList) => {
    let newSiteList = {};

    let forceStatus = undefined;

    if (
      Object.keys(siteList)
        .map((siteGuid) => siteList[siteGuid])
        .filter((site) => site.checkboxStatus === true).length === 0
    ) {
      forceStatus = false;
    }

    Object.keys(siteList).forEach((siteGuid) => {
      let site = siteList[siteGuid];
      let newGateways = {};

      Object.keys(site.gateways).forEach((gatewayGuid) => {
        let gateway = site.gateways[gatewayGuid];
        gateway.hidden =
          forceStatus !== undefined
            ? forceStatus
            : site.checkboxStatus === false;
        if (gateway.hidden === true) {
          gateway.checkboxStatus = false;
        }
        newGateways = { ...newGateways, [gatewayGuid]: gateway };
      });
      site.gateways = newGateways;
      newSiteList = { ...newSiteList, [siteGuid]: site };
    });

    return newSiteList;
  };

  getEnabledGateways = () => {
    let enabledGateways = [];

    Object.keys(this.state.siteList).forEach((siteGuid) => {
      const site = this.state.siteList[siteGuid];
      const { gateways } = site;

      Object.keys(gateways).forEach((gatewayGuid) => {
        const gateway = gateways[gatewayGuid];
        if (gateway.checkboxStatus === true) {
          enabledGateways.push(gatewayGuid);
        }
      });
    });

    if (enabledGateways.length === 0) {
      Object.keys(this.state.siteList).forEach((siteGuid) => {
        const site = this.state.siteList[siteGuid];
        const { gateways } = site;
        if (site.checkboxStatus === true) {
          Object.keys(gateways).forEach((gatewayGuid) => {
            enabledGateways.push(gatewayGuid);
          });
        }
      });
    }

    return enabledGateways;
  };

  toggleSort = (key) => {
    let { tableIconSort } = this.state;

    Object.keys(tableIconSort).forEach((col) => {
      if (key !== col) tableIconSort[col] = 0;
    });
    tableIconSort[key]++;
    if (tableIconSort[key] >= 3) tableIconSort[key] = 1;

    this.setState({ tableIconSort });
  };

  toggleModalProperties = () => {
    this.setState({ showPropertiesModal: !this.state.showPropertiesModal });
  };

  render() {
    let { licenses } = this.state;

    let sort = Object.keys(this.state.tableIconSort).filter(
      (x) => this.state.tableIconSort[x] !== 0
    );

    if (sort.length > 0) {
      sort = sort[0];
      let sortBy = this.state.tableIconSort[sort];

      switch (sort) {
        // Type
        case tableColumns[0]:
          licenses.sort((a, b) =>
            a.name > b.name ? (sortBy === 1 ? 1 : -1) : sortBy === 1 ? -1 : 1
          );
          break;
        // Status
        case tableColumns[1]:
          licenses.sort((a, b) => {
            const getItemStatus = (l) => {
              let itemRet = 0;
              if (l.isActive && l.gateway !== null) itemRet ^= 1;
              if (l.isExpiring) itemRet ^= 2;
              if (l.isExpired) itemRet ^= 4;
              if (!l.isActive && l.gateway !== null) itemRet ^= 8;
              if (l.isActive && l.gateway === null) itemRet ^= 16;
              if (!l.isActive && !l.isExpired && l.gateway === null)
                itemRet ^= 32;

              return itemRet;
            };

            let statusA = getItemStatus(a);
            let statusB = getItemStatus(b);

            return statusA > statusB
              ? sortBy === 1
                ? 1
                : -1
              : sortBy === 1
              ? -1
              : 1;
          });
          break;
        // Duration
        case tableColumns[2]:
          licenses.sort((a, b) =>
            a.duration > b.duration
              ? sortBy === 1
                ? 1
                : -1
              : sortBy === 1
              ? -1
              : 1
          );
          break;
        // Activation Date
        case tableColumns[3]:
          licenses.sort((a, b) => {
            let itemA = a.activeFrom ? new Date(a.activeFrom) : new Date(0);
            let itemB = b.activeFrom ? new Date(b.activeFrom) : new Date(0);

            return itemA > itemB
              ? sortBy === 1
                ? 1
                : -1
              : sortBy === 1
              ? -1
              : 1;
          });
          break;
        // Expiration Date
        case tableColumns[4]:
          licenses.sort((a, b) => {
            let itemA = a.expirationDate
              ? new Date(a.expirationDate)
              : new Date(0);
            let itemB = b.expirationDate
              ? new Date(b.expirationDate)
              : new Date(0);

            return itemA > itemB
              ? sortBy === 1
                ? 1
                : -1
              : sortBy === 1
              ? -1
              : 1;
          });
          break;
        // Time Left
        case tableColumns[5]:
          licenses.sort((a, b) => {
            let timeLeftA = !a.isActive ? -1 : a.timeLeftInt;
            let timeLeftB = !b.isActive ? -1 : b.timeLeftInt;

            if (timeLeftA === -1) return 1;

            return timeLeftA > timeLeftB
              ? sortBy === 1
                ? 1
                : -1
              : sortBy === 1
              ? -1
              : 1;
          });
          break;
        // Gateway
        case tableColumns[6]:
          licenses.sort((a, b) => {
            if (a.gateway === null) return 1;
            if (b.gateway === null) return -1;
            return a.gateway.name > b.gateway.name
              ? sortBy === 1
                ? 1
                : -1
              : sortBy === 1
              ? -1
              : 1;
          });
          break;
        default:
          break;
      }
    }

    return (
      <Card>
        <CardBody>
          <div id="filters-modal">
            <Button onClick={this.toggleModalProperties}>
              {this.props.t("license.showFilters")}
            </Button>
            <Modal
              isOpen={this.state.showPropertiesModal}
              toggle={this.toggleModalProperties}
              centered
              className="license-filter-modal"
            >
              <ModalBody className="license-filter-modal-body">
                <span className="h2">{this.props.t("license.filters")}</span>
                <span
                  className="license-filters-modal-close"
                  onClick={this.toggleModalProperties}
                >
                  X
                </span>
                <LicenseListFilters
                  id="license-filters-container-modal"
                  t={this.props.t}
                  siteList={this.state.siteList}
                  filtersItems={this.state.filtersItems}
                  modal={true}
                />
              </ModalBody>
            </Modal>
          </div>
          <LicenseListFilters
            id="license-filters-container"
            t={this.props.t}
            siteList={this.state.siteList}
            filtersItems={this.state.filtersItems}
            modal={false}
          />
          <div
            className="license-legend"
            show={this.state.showLicenseLegend.toString()}
          >
            <div
              className="mb-2 license-legend-title"
              onClick={() =>
                this.setState({
                  showLicenseLegend: !this.state.showLicenseLegend,
                })
              }
            >
              <span className="font-weight-bold mr-2">
                {this.props.t("license.licenseLegend")}
              </span>
              <span className="text-light float-right">{icons.ICON_INFO}</span>
            </div>
            <div>
              <div>
                <div className="mb-2">
                  <span className="license-list-state-icon bg-success text-white mr-2">
                    {icons.ICON_OK}
                  </span>
                  <span>{this.props.t("license.activeLicenses")}</span>
                </div>
                <div className="mb-2">
                  <span className="license-list-state-icon bg-yellow text-white mr-2">
                    {icons.ICON_OK}
                  </span>
                  <span>{this.props.t("license.expiringLicenses")}</span>
                </div>
                <div className="mb-2">
                  <span className="license-list-state-icon bg-danger text-white mr-2">
                    {icons.ICON_ERROR}
                  </span>
                  <span>{this.props.t("license.expiredLicenses")}</span>
                </div>
                <div className="mb-2">
                  <span className="license-list-state-icon bg-primary text-white mr-2">
                    {icons.ICON_LICENSE_DEFERRED}
                  </span>
                  <span>{this.props.t("license.nextLicenses")}</span>
                </div>
              </div>
              <div className="mt-4">
                <div className="mb-2">
                  {this.props.t("license.licensePermissions")}
                </div>
                <div className="mb-2">
                  <span className="license-list-state-icon mr-2">
                    {icons.ICON_ENTITY_VIEWER}
                  </span>
                  <span>{this.props.t("license.licenseViewOnly")}</span>
                </div>
              </div>
            </div>
          </div>
          <Table hover bordered size="sm" responsive>
            <thead>
              <tr className="bg-secondary">
                {tableColumns.map((k, i) => {
                  const icon = utils.getSortIcon(this.state.tableIconSort[k]);
                  return (
                    <th key={i}>
                      <span className="license-table-header-th">
                        <span>{this.props.t(k)}</span>
                        <span
                          onClick={() => {
                            this.toggleSort(k);
                          }}
                        >
                          {icon}
                        </span>
                      </span>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="license-table-body">
              {licenses.map((l, i) => {
                const { gateway } = l;
                const { filtersItems } = this.state;

                if (
                  filtersItems.filter(
                    (filter) => filter.checkboxStatus === true
                  ).length > 0
                ) {
                  const activeFilter = filtersItems[0].checkboxStatus;
                  const expiredFilter = filtersItems[1].checkboxStatus;
                  const expiringFilter = filtersItems[2].checkboxStatus;
                  const nextFilter = filtersItems[3].checkboxStatus;
                  const notAssignedFilter = filtersItems[4].checkboxStatus;

                  let show = false;

                  if (activeFilter) {
                    if (l.isActive) {
                      show = true;
                    }
                  }

                  if (expiredFilter) {
                    if (l.isExpired) {
                      show = true;
                    }
                  }

                  if (expiringFilter) {
                    if (l.isExpiring) {
                      show = true;
                    }
                  }

                  if (nextFilter) {
                    if (!l.isActive && !l.isExpired && gateway !== null) {
                      show = true;
                    }
                  }

                  if (notAssignedFilter) {
                    if (!l.assigned) {
                      show = true;
                    }
                  }

                  if (!show) {
                    return null;
                  }
                }

                const enabledGateways = this.getEnabledGateways();
                if (enabledGateways.length > 0) {
                  if (
                    gateway === null ||
                    !enabledGateways.includes(gateway.gatewayGuid)
                  )
                    return null;
                }

                let iconResult = utils.getLicenseIconAndColor(l);
                let icon = iconResult === null ? null : iconResult[0];
                let iconColor = iconResult === null ? "" : iconResult[1];

                if (l.hidden) return null;

                const strangeLicense = l.isActive && l.gateway === null;

                return (
                  <tr
                    key={i}
                    className="license-table-record"
                    style={{
                      background: `${
                        l.isExpired
                          ? utils.getArgonColors("danger") + "4F"
                          : strangeLicense
                          ? utils.getArgonColors("yellow") + "4F"
                          : ""
                      }`,
                    }}
                  >
                    <td>
                      <span className="mr-2">
                        {l.owned ? null : icons.ICON_ENTITY_VIEWER}
                      </span>
                      <span>{l.name || ""}</span>
                      <span className="float-right text-light license-detail-icon">
                        <LicenseInfo
                          license={l}
                          t={this.props.t}
                          language={this.props.language}
                          modalOnly
                        />
                      </span>
                    </td>
                    <td className={`license-list-state`}>
                      <span
                        className={`license-list-state-icon ${iconColor} text-white
                        `}
                      >
                        {icon}
                      </span>
                    </td>
                    <td>{l.licenseTime}</td>
                    <td>
                      {l.activeFrom
                        ? new Date(l.activeFrom).toLocaleDateString(
                            this.props.language,
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }
                          )
                        : ""}
                    </td>
                    <td>
                      {l.expirationDate
                        ? l.expirationDate.toLocaleDateString(
                            this.props.language,
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }
                          )
                        : ""}
                    </td>
                    <td>{!l.isExpired && l.isActive ? l.timeLeft : ""}</td>
                    <td
                      className={`${
                        !l.gateway &&
                        (l.isActive || l.isExpired || l.activeFrom)
                          ? "license-gateway-not-visible-icon"
                          : ""
                      }`}
                    >
                      <span
                        className={`mr-1 ${
                          !l.gateway &&
                          (l.isActive || l.isExpired || l.activeFrom)
                            ? "opacity-4"
                            : "d-none"
                        }`}
                      >
                        {icons.ICON_INFO}
                      </span>
                      {!l.gateway &&
                      (l.isActive || l.isExpired || l.activeFrom) ? (
                        <div className="license-gateway-not-visible bg-white p-2 border border-warning rounded">
                          {this.props.t("license.notVisibleGatewayWarning")}
                        </div>
                      ) : null}
                      <span
                        className={`${
                          !l.gateway &&
                          (l.isActive || l.isExpired || l.activeFrom)
                            ? "opacity-4"
                            : ""
                        }`}
                      >
                        <span>
                          {l.gateway && l.gateway.name
                            ? l.gateway.name
                            : l.isActive || l.isExpired || l.activeFrom
                            ? this.props.t("license.notVisible")
                            : ""}
                        </span>
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    );
  }
}
