/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  Dropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Media,
} from "reactstrap";
import Preferences from "components/Forms/Preferences";
import keys from "configs/constants";
import { UpdateUserProfilePolicy, msalAuth } from "services/backendService";
import { bindActionCreators } from "redux";
import { apiAction, setControllers, setUser } from "redux/actions/index";
import api from "../../services/backendService";
import { withTranslation } from "react-i18next";
import "./style.css";
import utils from "utils";

class Name extends Component {
  setControllersOnSite = (itemInfo) => {
    this.props.apiAction(
      api.getDevicesOnSite,
      [itemInfo.siteGuid],
      setControllers,
      true
    );
    this.props.onSelect();
  };

  render() {
    let itemTitle = {};
    let itemLink = "";
    let itemInfo = {};

    switch (this.props.itemInfo.type) {
      case "site":
        itemTitle = { title: this.props.name, icon: keys.ICON_SITE_MAP };
        itemLink = "/std/dvcs";
        itemInfo = this.props.itemInfo;
        break;
      case "gateway":
        itemTitle = {
          title: this.props.name,
          icon: keys.ICON_GATEWAY,
          subtitles: [
            { text: this.props.itemInfo.extra ?? "" },
            { text: this.props.itemInfo.siteInfo.name, prefix: "Site" },
          ],
        };
        itemLink = "/std/dvcs";
        itemInfo = this.props.itemInfo.siteInfo;
        break;
      case "device":
        itemTitle = {
          title: this.props.name,
          icon: keys.ICON_CONTROLLER,
          subtitles: [
            {
              text:
                this.props.itemInfo.extra !== ""
                  ? this.props.itemInfo.extra
                  : undefined,
            },
            { text: this.props.itemInfo.gatewayInfo.name, prefix: "Gateway" },
          ],
        };
        itemLink = "/std/dvcs";
        itemInfo = this.props.itemInfo.gatewayInfo.siteInfo;
        break;
    }

    return (
      <Link
        to={{ pathname: itemLink, site: itemInfo }}
        onClick={() => this.setControllersOnSite(itemInfo)}
      >
        <div className="search-item">
          <div className="search-item-title">
            <span className="search-item-icon">{itemTitle.icon}</span>
            <span>{itemTitle.title}</span>
          </div>
          {itemTitle.subtitles?.map((subtitle, index) => (
            <div className="search-item-subtitle" key={index}>
              {subtitle.prefix && (
                <span className="search-item-subtitle-prefix">
                  {subtitle.prefix}:{" "}
                </span>
              )}
              <span>{subtitle.text}</span>
            </div>
          ))}
        </div>
      </Link>
    );
  }
}

export class SearchContainer extends Component {
  render() {
    return (
      <>
        {this.props.items.length === 0 ? (
          <div></div>
        ) : (
          <div
            className={`search-result${window.innerWidth < 767 ? "-sm" : ""}`}
          >
            {this.props.items.map((item) => (
              <Name
                key={item.guid}
                name={item.name}
                itemInfo={item}
                apiAction={this.props.apiAction}
                onSelect={this.props.onSelect}
              />
            ))}
          </div>
        )}
      </>
    );
  }
}

class ThinHeader extends Component {
  state = {
    searchTerm: "",
    isOpen: false,
    isPreferencesOpen: false,
  };

  handleClick = (e) => {
    if (e.currentTarget.getAttribute("value") === "logout") {
      this.props.onSignOut();
    }
    if (e.currentTarget.getAttribute("value") === "profile") {
      msalAuth.loginRedirect({ authority: UpdateUserProfilePolicy });
    }
  };

  onInputChange = (e) => {
    this.setState({ searchTerm: e.target.value });
  };

  clearSearch = () => {
    this.setState({ searchTerm: "" });
  };

  dynamicSearch = () => {
    if (this.state.searchTerm === "") return [];
    const s = this.props.searchItems.filter((item) => {
      return (
        item.name.toLowerCase().includes(this.state.searchTerm.toLowerCase()) ||
        item.extra.toLowerCase().includes(this.state.searchTerm.toLowerCase())
      );
    });
    return s;
  };

  toggleMenu = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  togglePreferencesModal = (status = undefined) => {
    this.setState({
      isPreferencesOpen:
        typeof status !== "boolean" ? !this.state.isPreferencesOpen : status,
    });
  };

  render() {
    const userFamilyName = this.props.auth.user.idToken.family_name;
    const userGivenName = this.props.auth.user.idToken.given_name;
    const username = this.props.auth.user.idToken.name;

    const itemsFound = this.dynamicSearch();

    return (
      <>
        <Preferences
          isOpen={this.state.isPreferencesOpen}
          toggle={this.togglePreferencesModal}
          auth={this.props.auth}
          setUser={this.props.setUser}
          t={this.props.t}
        />
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            {this.props.t("sidebar." + this.props.brandText)}
          </Link>
          {window.location.pathname.includes("/ad") ? null : (
            <>
              <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
                <FormGroup className="mb-0">
                  <InputGroup className="input-group-alternative">
                    <InputGroupText>{keys.ICON_SEARCH}</InputGroupText>
                    <Input
                      placeholder={this.props.t("sidebar.search")}
                      value={this.state.searchTerm}
                      onChange={this.onInputChange}
                      type="text"
                    />
                  </InputGroup>
                </FormGroup>
              </Form>
              <SearchContainer
                items={itemsFound}
                apiAction={this.props.apiAction}
                onSelect={this.clearSearch}
              />
            </>
          )}
          <Nav navbar>
            <Dropdown isOpen={this.state.isOpen} toggle={this.toggleMenu}>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <Media className="text-center ml-2 d-none d-md-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {username}
                    </span>
                    <br />
                    <span className="text-center mb-0 small">
                      ({userGivenName + " " + userFamilyName})
                    </span>
                    <br />
                    <div className="text-center">
                      {utils.isUserAdmin() && (
                        <span className="badge bg-danger text-white">
                          SuperAdmin
                        </span>
                      )}
                    </div>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow dropdown-user" end>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">
                    {this.props.t("navbar.welcome") + "!"}
                  </h6>
                </DropdownItem>
                <DropdownItem>
                  <div value="profile" onClick={this.handleClick.bind(this)}>
                    <span className="btn-inner--icon">
                      {keys.ICON_USERPROFILE}
                    </span>
                    <span className="ml-2 btn-inner--text">
                      {this.props.t("navbar.myProfile")}
                    </span>
                  </div>
                </DropdownItem>
                {/* Preferences */}
                <div
                  className="dropdown-item"
                  value="preferences"
                  onClick={this.togglePreferencesModal}
                >
                  <span className="btn-inner--icon">
                    {keys.ICON_PREFERENCES}
                  </span>
                  <span className="ml-2 btn-inner--text cursor-default">
                    {this.props.t("navbar.preferences")}
                  </span>
                </div>
                <DropdownItem divider />
                {utils.isUserAdmin() && (
                  <>
                    <DropdownItem>
                      <a
                        href={window.location.origin + "/ddf"}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="btn-inner--icon">
                          {keys.ICON_DEVICE_DESCRIPTOR}
                        </span>
                        <span className="ml-2 btn-inner--text cursor-default">
                          Device Description File Manager
                        </span>
                      </a>
                    </DropdownItem>
                  </>
                )}
                {utils.isUserAdmin() && (
                  <>
                    <DropdownItem>
                      <Link
                        to={{
                          pathname: "/std/kpi",
                        }}
                      >
                        <span className="btn-inner--icon">{keys.ICON_KPI}</span>
                        <span className="ml-2 btn-inner--text cursor-default">
                          {this.props.t("sidebar.performance")}
                        </span>
                      </Link>
                    </DropdownItem>
                    <DropdownItem divider />
                  </>
                )}
                <DropdownItem>
                  <div value="logout" onClick={this.handleClick.bind(this)}>
                    <span className="btn-inner--icon">{keys.ICON_LOGOUT}</span>
                    <span className="ml-2 btn-inner--text">
                      {this.props.t("navbar.logout")}
                    </span>
                  </div>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Nav>
        </Navbar>
      </>
    );
  }
}

export const mapSearchItemsToProps = (state) => {
  let searchItems = [];
  for (const s of Object.values(state.sites.byId)) {
    searchItems.push({
      name: s.name,
      guid: s.siteGuid,
      type: "site",
      extra: "",
      siteGuid: s.siteGuid,
      city: s.city,
      country: s.country,
      connectivitytype: s.connectivitytype,
      latitude: s.latitude,
      longitude: s.longitude,
    });
    for (const gw of s.gateways) {
      searchItems.push({
        name: gw.name,
        guid: gw.gatewayGuid,
        type: "gateway",
        extra: gw.macAddress,
        siteInfo: {
          name: s.name,
          guid: s.siteGuid,
          type: "site",
          extra: "",
          siteGuid: s.siteGuid,
          city: s.city,
          country: s.country,
          connectivitytype: s.connectivitytype,
          latitude: s.latitude,
          longitude: s.longitude,
        },
      });

      const gatewayDetails = state.gateways.byId[gw.gatewayGuid];

      for (const dv of gatewayDetails.devices || []) {
        const name =
          dv.friendlyName && dv.friendlyName !== ""
            ? dv.friendlyName
            : dv.model;
        const extra = dv.friendlyName && dv.friendlyName !== "" ? dv.model : "";

        searchItems.push({
          name: name,
          guid: dv.deviceGuid,
          type: "device",
          extra: extra,
          gatewayInfo: {
            name: gw.name,
            guid: gw.gatewayGuid,
            type: "gateway",
            extra: gw.macAddress,
            siteInfo: {
              name: s.name,
              guid: s.siteGuid,
              type: "site",
              extra: "",
              siteGuid: s.siteGuid,
              city: s.city,
              country: s.country,
              connectivitytype: s.connectivitytype,
              latitude: s.latitude,
              longitude: s.longitude,
            },
          },
        });
      }
    }
  }

  return { searchItems };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      apiAction,
      setUser,
    },
    dispatch
  );

export default withTranslation("common")(
  connect(mapSearchItemsToProps, mapDispatchToProps)(ThinHeader)
);
