/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import HeaderContainer from "components/Headers/HeaderContainer";
import ThinHeader from "components/Headers/ThinHeader";

import routes from "routes.js";
import keys from "configs/constants";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { setUser, setLanguages } from "../redux/actions/index";
import { getUserAttribute } from "layouts/common";

class Admin extends React.Component {
  constructor(props) {
    super(props);

    let userInfo = {
      name: this.props.auth.user.name,
      family_name: this.props.auth.user.idToken.family_name,
      given_name: this.props.auth.user.idToken.given_name,
      role: this.props.auth.user.idToken.role,
    };

    getUserAttribute().then((attributes) => {
      userInfo = { ...userInfo, ...attributes };
      props.setUser(userInfo);
    });
  }

  componentDidMount = async () => {
    const { language } = this.props.auth.user.idToken.settings;
    await this.props.setCurrentLanguage(language);
  };

  componentDidUpdate(_e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    //this.refs.mainContent.scrollTop = 0; --> refs is deprecated
  }

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/ad") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "brand";
  };
  render() {
    return (
      <>
        <Sidebar
          {...this.props}
          routes={routes}
          logo={{
            imgAlt: "Logo",
            imgSrc: keys.LOGO_URL,
            innerLink: "/std/index",
          }}
          onlyAdmin={true}
        />
        <div className="main-content">
          {/* ref="mainContent"> generates error */}
          <HeaderContainer>
            <ThinHeader
              {...this.props}
              brandText={this.getBrandText(this.props.location.pathname)}
            />
          </HeaderContainer>
          <Switch>
            {this.getRoutes(routes)}
            <Redirect from="*" to="/ad" />
          </Switch>
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setUser,
      setLanguages,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Admin);
