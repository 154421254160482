import {
  SET_GATEWAYS,
  GATEWAY_UPDATE,
  UPDATE_GATEWAY_FROM_STREAM,
  UPDATE_GATEWAY_CONTROLLERS,
  RESET_GATEWAYS,
  UPDATE_LAST_GATEWAY_STATUS_FROM_STREAM,
} from "../actions/index";

const initialData = { byId: {}, allIds: [] };

const gateways = (state = initialData, action) => {
  const { payload } = action;

  switch (action.type) {
    case SET_GATEWAYS:
      return action.payload;
    case RESET_GATEWAYS:
      return initialData;
    case GATEWAY_UPDATE:
      let newState = { ...state };

      newState.byId[action.payload.gatewayGuid].name = action.payload.name;
      newState.byId[action.payload.gatewayGuid].telemetryPushInterval =
        action.payload.telemetryPushInterval;

      return newState;
    case UPDATE_LAST_GATEWAY_STATUS_FROM_STREAM:
      if (state.byId[action.payload.gatewayGuid] === undefined) return state;

      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.gatewayGuid]: {
            ...state.byId[action.payload.gatewayGuid],
            lastStatus: action.payload.status,
          },
        },
      };
    case UPDATE_GATEWAY_FROM_STREAM:
      if (state.byId[action.payload.gatewayGuid] === undefined) return state;

      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.gatewayGuid]: {
            ...state.byId[action.payload.gatewayGuid],
            status: action.payload.status === 1 ? "ON" : "OFF",
            currentDateTime: action.payload.currentDateTime,
            lastDataSentOn: action.payload.lastSeenOn,
            releaseFW: action.payload.releaseFW,
          },
        },
      };
    case UPDATE_GATEWAY_CONTROLLERS:
      let gateways = state;
      const { gatewayGuid, deviceGuid, friendlyName } = payload;

      gateways.byId[gatewayGuid].devices.forEach((device) => {
        if (device.deviceGuid === deviceGuid)
          device.friendlyName = friendlyName;
      });

      return gateways;
    default:
      return state;
  }
};

export default gateways;
