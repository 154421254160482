import { UPDATE_CURRENT_PAGE } from "../actions/index";

/* wna stands for Web Navigation. I know it's poorly
 * meaningful, however this is potentially required to be
 * fully or partially saved on local storage to restore state
 * in case of page reload, thus... I prefer to have this info
 * this way */
const wna = (state = { current: null, usedProps: null }, action) => {
  switch (action.type) {
    case UPDATE_CURRENT_PAGE:
      if (action.currentLocation === null) return state;
      let props = Object.assign({}, action.currentLocation);
      delete props.key;
      delete props.pathname;
      delete props.search;
      delete props.hash;
      if (Object.keys(props).length === 1 && Object.keys(props)[0] === "state")
        props = null;

      let lastItem = JSON.parse(window.sessionStorage.getItem("li")) ?? {};

      lastItem.deviceGuid = props?.site?.deviceGuid ?? lastItem.deviceGuid;
      lastItem.address = props?.site?.address ?? lastItem.address;
      lastItem.siteInfo = props?.site?.siteInfo ?? lastItem.siteInfo;

      window.sessionStorage.setItem("li", JSON.stringify(lastItem));

      window.sessionStorage.setItem(
        "cn",
        JSON.stringify({
          current: action.currentLocation.pathname,
          usedProps: props,
        })
      );
      return {
        ...state,
        current: action.currentLocation.pathname,
        usedProps: props,
      };
    default:
      return state;
  }
};

export default wna;
