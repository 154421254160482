const NOMINATIM_URL = 'https://nominatim.openstreetmap.org/'

const getLocationFromCoordinates = async (lat, lon) => {
    const url = `${NOMINATIM_URL}reverse?format=json&lat=${lat}&lon=${lon}`
    const response = await fetch(url)
    return {status: response.status, data: await response.json()}
}

const searchGeoLocation = async (query) => {
    const url = `${NOMINATIM_URL}search?format=json&q=${query}&limit=5&addressdetails=1`
    const response = await fetch(url)
    return {status: response.status, data: await response.json()}
}

export default { getLocationFromCoordinates, searchGeoLocation }
