import React, { Component } from "react";
import isBoolean from "validator/lib/isBoolean";
import icons from "../../configs/constants";
import { Modal, ModalBody, ModalHeader, Table } from "reactstrap";

const licenseContentFields = {
  // FUTURE FEATURE: Removed right now because it is not completely implemented. We will add it back when it is ready.
  // realtimeCharting: {
  //   type: "bool",
  //   field: "frontend",
  // },
  foodQualityReport: {
    type: "bool",
    field: "frontend",
  },
  virtualAlarms: {
    type: "int",
    field: "api",
  },
  emailNotify: {
    type: "int",
    field: "api",
  },
  gatewayUsers: {
    type: "int",
    field: "api",
  },
  dailyMailOn: {
    type: "int",
    field: "db",
  },
  dailyMailOff: {
    type: "int",
    field: "db",
  },
  maxDevices: {
    type: "int",
    field: "frontend",
  },
};

export class LicenseInfo extends Component {
  constructor(props) {
    super(props);

    this.state = { isLicenseDetailsModalOpen: false };
  }

  printLicenseDuration = () => {
    if (this.props.license.duration) {
      return `${this.props.license.licenseTime} ${this.props
        .t("license.periodValidity")
        .toLowerCase()}`;
    }
  };

  toggleLicenseDetailsModal = (status = undefined) => {
    this.setState({
      newActivationCode: null,
      isLicenseDetailsModalOpen:
        status && isBoolean(status.toString())
          ? status
          : !this.state.isLicenseDetailsModalOpen,
    });
  };

  renderLicenseDetailModal = () => {
    const content = JSON.parse(this.props.license.content);
    return (
      <Modal
        isOpen={this.state.isLicenseDetailsModalOpen}
        toggle={this.toggleLicenseDetailsModal}
        centered
        className="license-details-modal"
      >
        <ModalHeader className="bg-lighter">
          <span className="h2">{this.props.t("license.licenseDetails")}</span>
        </ModalHeader>
        <ModalBody className="pt-4">
          <div className="mb-4">
            <span className="mr-2 font-weight-bold">
              {this.props.t("license.type") + ":"}
            </span>
            <span>{content.name}</span>
            <p className="text-light">{this.printLicenseDuration()} </p>
          </div>
          <Table>
            <tbody>
              {Object.keys(content.data).map((key, i) => {
                try {
                  const fieldData =
                    licenseContentFields[
                      Object.keys(licenseContentFields).filter(
                        (f) => f === key
                      )[0]
                    ];

                  let fieldInfo = null;

                  switch (fieldData.type) {
                    case "bool":
                      const status = content.data[key][fieldData.field] !== 0;
                      fieldInfo = (
                        <span>
                          <input
                            type="checkbox"
                            className="license-field-status"
                            defaultChecked={status}
                          />
                          <span
                            className={`checkMark ${
                              status ? "" : "checkMark-off"
                            }`}
                          />
                        </span>
                      );
                      break;
                    case "int":
                      fieldInfo = (
                        <span className="license-field-value">
                          {content.data[key][fieldData.field] === -1 ? this.props.t("license.unlimited") : content.data[key][fieldData.field]}
                        </span>
                      );
                      break;
                    default:
                      break;
                  }

                  return (
                    <tr key={i}>
                      <th>{this.props.t("license." + key)}</th>
                      <td>{fieldInfo}</td>
                    </tr>
                  );
                } catch (e) {
                  return null;
                }
              })}
            </tbody>
          </Table>
        </ModalBody>
      </Modal>
    );
  };

  render() {
    const { activeFrom, expirationDate } = this.props.license;

    return (
      <>
        {this.renderLicenseDetailModal()}
        {this.props.modalOnly ? (
          <div onClick={this.toggleLicenseDetailsModal}>{icons.ICON_INFO}</div>
        ) : (
          <div className="d-flex justify-content-center flex-column align-items-center">
            {this.props.licenseIcon}

            <div className="h5">
              <span className="font-weight-light mr-1">
                {this.props.t("license.activeFrom")}
              </span>
              <span>
                {new Date(activeFrom).toLocaleDateString(this.props.language, {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </span>
            </div>

            <div className="h5 mt-1 mb-4">
              <span className="font-weight-light mr-1">
                {this.props.t("license.expiresOn")}
              </span>
              <span>
                {new Date(expirationDate).toLocaleDateString(
                  this.props.language,
                  {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }
                )}
              </span>
            </div>

            <div
              className="text-light license-details"
              onClick={this.toggleLicenseDetailsModal}
            >
              <span className="mr-1">
                {this.props.t("license.licenseDetails")}
              </span>
              <span className="h3 text-light">{icons.ICON_INFO}</span>
            </div>
          </div>
        )}
      </>
    );
  }
}
