import keys from "configs/constants";
import { getAPICallAuthHeader } from "./backendService";

const callCoreIAM = async (method, endpoint, body) => {
  const url = `${keys.CORE_API_URL}/${endpoint}`;
  const result = {};
  const request = {};

  request.method = method;
  let config = await getAPICallAuthHeader();
  if (config === "logout") return null;
  config = {
    ...config,
    headers: {
      ...config.headers,
      "Content-Type":
        method === "PATCH"
          ? "application/json-patch+json; charset=utf-8"
          : "application/json; charset=utf-8",
    },
  };
  request.headers = config.headers;
  request.body = body ? JSON.stringify(body) : null;

  const response = await fetch(url, request);
  result.status = response.status;
  result.data = null;
  result.responseString = "";

  if (!response.ok) {
    result.responseString = (
      response.statusText ||
      response.status ||
      "Unknown Error"
    ).toString();
    try {
      result.data = await response.json();
    }catch(e){}
    return Promise.reject(result);
  }
  if (response.status !== 204) {
    result.data = await response.json();
    result.responseString = (
      response.data ||
      response.statusText ||
      response.status ||
      "Unknown Error"
    ).toString();
  }
  return Promise.resolve(result.data);
};

export async function getSelfAsync() {
  return await callCoreIAM("GET", "users/me", undefined);
}

export async function getGroupById(groupId, includeMembers = false, includeDevices = false, includeRoles = false, includeSubGroups = false) {
  return await callCoreIAM("GET", `groups/${groupId}?includeMembers=${includeMembers}&includeDevices=${includeDevices}&includeRoles=${includeRoles}&includeSubGroups=${includeSubGroups}`, undefined);
}

export async function getUsersByGroup(groupId) {
  return await callCoreIAM("GET", `groups/${groupId}/users`, undefined);
}

export async function getUserById(userId) {
  return await callCoreIAM("GET", `users/${userId}`, undefined);
}

export async function addUsersToGroup(groupId, roleId, users = []) {
  return await callCoreIAM("POST", `groups/${groupId}/users/batch-add?roleId=${roleId}`, users);
}

export async function editUserRole (groupId, userId, roleId) {
  return await callCoreIAM("PUT", `groups/${groupId}/users/${userId}/role/${roleId}`, undefined);
}

export async function removeUserFromGroup(groupId, users = [], removeFromAllParentGroups = false, removeFromAllChildGroups = false) {
  return await callCoreIAM("POST", `groups/${groupId}/users/batch-remove?removeFromAllParentGroups=${removeFromAllParentGroups}&removeFromAllChildGroups=${removeFromAllChildGroups}`, users);
}

export async function getSelfGroups() {
  return  await callCoreIAM("GET", "groups?includeGroupRoles=true", undefined);
}

const mapKeyForPatchSettings = (key) => {
  switch (key.toString().toLowerCase()) {
    case "lang":
      return "settings/language";
    case "temp_unit":
      return "settings/temperatureUnit";
    case "press_unit":
      return "settings/pressureUnit";
    default:
      return key;
  }
};

export async function patchSelfAsync(props) {
  const body = Object.entries(props).map(([key, value]) => ({
    op: "replace",
    path: `/${mapKeyForPatchSettings(key)}`,
    value: value,
  }));

  return await callCoreIAM("PATCH", "users/me", body);
}
