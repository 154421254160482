/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

class BackgroundHeader extends React.Component {
  render() {
    return (
      <>
        <div className="bg-gradient-info pb-9 pt-5 pt-md-7">{this.props.children}</div>
      </>
    );
  }
}

export default BackgroundHeader;
