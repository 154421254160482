import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import License from "../components/License";
import { connect } from "react-redux";

class UserLicense extends Component {
  render() {
    return <License t={this.props.t} />;
  }
}

const mapStateToProps = () => ({});

export default withTranslation("common")(connect(mapStateToProps)(UserLicense));
