import { SET_USER } from "../actions/index";

const defaultUser = {
  name: null,
  family_name: null,
  given_name: null,
  role: null,
  language: null,
};

const user = (state = defaultUser, action) => {
  switch (action.type) {
    case SET_USER:
      return action.payload;
    default:
      return state;
  }
};

export default user;
