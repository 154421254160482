import React, { Component } from "react";
import "./style.css";

class Selector extends Component {
  componentDidMount = () => {
    this.setActiveSelector();
  };

  componentDidUpdate = () => {
    this.setActiveSelector();
  };

  setActiveSelector = () => {
    let selectorContainer = document.getElementById(this.props.id);
    let selectorContainerHeight =
      selectorContainer.getBoundingClientRect().height;

    let offSpan = document.getElementById(this.props.id + "-0");
    let onSpan = document.getElementById(this.props.id + "-1");

    let offSpanWidth = offSpan.getBoundingClientRect().width;
    let onSpanWidth = onSpan.getBoundingClientRect().width;

    const status = this.props.disabled
      ? this.props.default || false
      : this.props.status;

    let activeWidth = !status ? offSpanWidth : onSpanWidth;

    let r = document.querySelector("#" + this.props.id);

    var rs = getComputedStyle(r);
    let globalMargin = parseInt(
      rs.getPropertyValue("--global-margin").split("px")[0]
    );

    r.style.setProperty(
      "--selector-height",
      selectorContainerHeight - globalMargin * 2 + "px"
    );
    r.style.setProperty(
      "--selector-width",
      activeWidth - globalMargin * 2 + "px"
    );
    r.style.setProperty(
      "--left-pos",
      (!status ? globalMargin : offSpanWidth + 10 + globalMargin) + "px"
    );
  };

  toggle = () => {
    if (!this.props.disabled) {
      this.props.toggle();
    }
    this.setActiveSelector();
  };

  render() {
    return (
      <div
        className={`${this.props.className || ""} selector-container ${
          this.props.disabled ? "selector-disabled" : ""
        }`}
        onClick={this.toggle}
        id={this.props.id}
      >
        {this.props.items.map((item, i) => {
          return (
            <span key={i} id={this.props.id + "-" + i}>
              {item}
            </span>
          );
        })}
        <span className={`selector-active ${this.props.disabled ? "" : ""}`} />
      </div>
    );
  }
}

export default Selector;
