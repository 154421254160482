import React, { useState, useEffect } from 'react'
import Loader from 'components/Loader';
import { Redirect } from "react-router-dom";
import api from "services/backendService";

const ExternalLink = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [parameters, setParameters] = useState({});

    useEffect(() => {
        loadPagesParametersAsync()
            .catch((err) => {
                setParameters({ 
                    pathname: undefined, 
                    error: 'Something went wrong', 
                    details: getErrorDetails(err)
                });
            })
            .finally(() => setIsLoading(false))
    }, []);

    const getErrorDetails = (err) => {
        if (err.data) {
            if (typeof err.data === 'string') return err.data;
            if (err.data['errors'])
            {
                const keys = Object.keys(err.data['errors']);
                return err.data['errors'][keys[0]];
            }
        }
        return err.toString();
    }

    const loadPagesParametersAsync = async () => {
        try{
            const { search } = props.location;
            const path = new URLSearchParams(search).get('path');
            if (!path) return Promise.reject('Path is required');

            switch (path) {
                case 'std/dvcs':
                    await loadDevicesParametersAsync(search);
                    break;
                case 'std/sv':
                    await loadSingleViewParametersAsync(search);
                    break;
                default:
                    return Promise.reject('Invalid path');
            }
        } catch (err) {
            return Promise.reject(err);
        }
    }

    const loadDevicesParametersAsync = async (search) => {
        const siteGuid = new URLSearchParams(search).get('siteGuid');
        if (!siteGuid) return Promise.reject('Site guid is required');

        const site = (await api.getSiteDetails(siteGuid)).data;
        setParameters({
            pathname: new URLSearchParams(search).get('path'),
            site: {
                siteGuid: siteGuid,
                name: site.name,
                city: site.city,
                country: site.country,
                connectivitytype: 'wifi',
                latitude: site.latitude,
                longitude: site.longitude,
            }
        });
    }

    const loadSingleViewParametersAsync = async (search) => {
        const deviceGuid = new URLSearchParams(search).get('deviceGuid');
        if (!deviceGuid) return Promise.reject('Device guid is required');

        const device = (await api.getDevice(deviceGuid)).data;
        const site = (await api.getSiteDetails(device.site.siteGuid)).data;
        const gatewayInfo = (await api.getSingleGatewayDetail(device.gateway.gatewayGuid)).data;
        setParameters({
            pathname: new URLSearchParams(search).get('path'),
            site: {
                siteGuid: device.site.siteGuid,
                gatewayGuid: device.gateway.gatewayGuid,
                deviceGuid: deviceGuid,
                name: device.model,
                address: device.address,
                siteInfo: {
                    siteGuid: device.site.siteGuid,
                    name: device.site.name,
                    city: site.city,
                    country: site.country,
                    connectivitytype: 'wifi',
                    latitude: site.latitude,
                    longitude: site.longitude,
                },
            },
            gatewayInfo,
        });
    }

    return isLoading
        ? <Loader />
        : parameters.pathname 
            ? <Redirect to={parameters} />
            : <div style={{
                    display:"flex", 
                    flexDirection: "column", 
                    justifyContent: "center", 
                    alignItems: "center", 
                    height: '100vh'}}>
                <div>{parameters.error}</div>
                <div>{parameters.details}</div>
            </div>
}

export default ExternalLink