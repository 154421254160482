import React from "react";

// reactstrap components
import { Row, Col } from "reactstrap";

import utils from "../utils";

class PointBox extends React.Component {
  state = {
    name: this.props.name,
    value: this.props.value,
    icon: this.props.icon || null,
    type: this.props.type || "integer",
  };

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  pointIcon(icon) {
    if (icon === null || icon === "") return null;

    let v = (
      <Col>
        <div className="icon icon-shape text-info">{icon}</div>
      </Col>
    );
    return v;
  }

  render() {
    const langText = utils.getLangText(this.state.name, this.props.customizationData);
    const value = this.state.value;
    const measureUnit = utils.getUnitByType(value, this.props.vtype);
    const className = `mt-2 ${
      value === "ERROR" || value === "BAD LINK" ? "text-warning" : null
    }`;
    return (
      <>
        <Col sm="6" md="3" style={{ display: "grid" }}>
          <Row className="text-center">
            <Col>
              <span className="font-weight-light">{langText}</span>
              <h3 className={className}>{value + measureUnit}</h3>
            </Col>
            {this.pointIcon(this.state.icon)}
          </Row>
        </Col>
      </>
    );
  }
}

export default PointBox;
