import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
import Loader from "components/Loader";
import { withTranslation } from "react-i18next";
import api, { sources } from "services/backendService";
import KPIBox from "./KPIBox";
import kpis from "./kpis";

import "./style.css";

class Performance extends Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: false };

    this.id = null;
  }

  componentWillUnmount() {
    if (sources[this.id]) sources[this.id].source.cancel();
  }

  getKPI = (message) => {
    this.setState({ isLoading: true });
    return new Promise((res, rej) => {
      api
        .getKpi(1, message)
        .then((response) => {
          response.execute
            .then((kpi) => {
              this.setState({
                isLoading: false,
              });
              res(kpi.data);
            })
            .catch((e) => {
              this.quitRequest(e);
              rej(e);
            });
        })
        .catch((e) => {
          this.quitRequest(e);
          rej(e);
        });
    });
  };

  quitRequest = (e) => {
    if (e.toString() !== "Cancel") {
      this.setState({
        isLoading: false,
      });
    }
  };

  renderKPIs = () => {
    return (
      <div className="kpis-container">
        {Object.keys(kpis).map((x, i) => {
          const Element = kpis[x];

          return (
            <KPIBox key={i} title={x}>
              <Element {...this.props} getKPI={this.getKPI} />
            </KPIBox>
          );
        })}
      </div>
    );
  };

  render() {
    return (
      <>
        {this.state.isLoading && <Loader />}
        <Card className="performance">
          <CardBody>{this.renderKPIs()}</CardBody>
        </Card>
      </>
    );
  }
}

export default withTranslation("common")(Performance);
