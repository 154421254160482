import React from "react"
import { Button, Input } from "reactstrap"
import ListDropdown from "../ListDropdown"
import "./style.css"

const InputAndButton = (props) => {

    const renderButton = () => {
        return props.listItems 
            ? <ListDropdown 
                className={props.listClassName}
                toggleClass={props.toggleClassName}
                menuClassName={props.menuClassName}
                color={props.color}
                title={props.title} 
                listItems={props.listItems}
                disabled={props.listDisabled}/> 
            : <Button
                className={`m-0 ${props.buttonClassName}`}
                color="primary"
                onClick={props.onClick}
                disabled={props.disabled}>
            {props.icon}
        </Button>
    }

    return <div className={`d-flex ${props.className}`} id="input-and-button">
        <Input
            id={props.id}
            className={props.inputClassName}
            placeholder={props.placeholder}
            type={props.type}
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)} />
        {renderButton()}
    </div>
}

export default InputAndButton;