import React, { Component } from "react";
import { Button, Table } from "reactstrap";
import ListDropdown from "components/ListDropdown";
import Selector from "components/Selector";
import ConnectionChart from "./ConnectionChart";
import { TooltipButton } from "components/Tooltip";
import utils from "utils";
import "./style.css";

class KPI01 extends Component {
  constructor(props) {
    super(props);

    const yesterday = utils
      .setOffsetDate(new Date(), 60 * 24)
      .toISOString()
      .split("T")[0];
    const today = new Date().toISOString().split("T")[0];

    this.state = {
      gateways: [],
      response: null,
      dayOrInterval: false, // false: day, true: interval
      start: yesterday,
      end: today,
      selectedGateway: null,
      selectAll: false,
    };
  }

  componentDidMount = async () => {
    let gws = [];

    for (let gwGuid of Object.keys(this.props.store.gateways.byId)) {
      const gw = this.props.store.gateways.byId[gwGuid];
      gws.push({
        guid: gwGuid,
        mac: gw.macAddress,
        text: gw.name,
        checkbox: true,
        checkboxStatus: true,
        command: () => {
          let { gateways } = this.state;
          for (let gw of gateways) {
            if (gw.guid === gwGuid) {
              gw.checkboxStatus = !gw.checkboxStatus;
            }
          }
          this.setState({ gateways });
        },
      });
    }

    this.setState({ gateways: gws });

    await this.getKPI(gws);
  };

  getKPI = async (gws = []) => {
    let gateways = gws.length > 0 ? gws : this.state.gateways;
    const gw = gateways
      .filter((x) => x.checkboxStatus === true)
      .map((x) => x.mac);

    const { start, end } = this.state;

    const interval = new Date(end);
    interval.setDate(interval.getDate());
    const nEnd = interval.toISOString().split("T")[0];

    const message = {
      macAddresses: gw,
      start: `${start} 00:00:00`,
      end: `${nEnd} 00:00:00`,
    };
    let response = await this.props.getKPI(message);

    this.selectGateway(0, response);
    this.setState({ response });
  };

  selectGateway = (i, response = null) => {
    const { gateways } = this.state;
    const r = response ? response : this.state.response;
    const { data } = r;

    const gw = gateways.filter((x) => Object.keys(data).includes(x.mac))[i];

    let selectedGateway = {
      ...data[gw.mac],
      ...gw,
    };

    this.setState({ selectedGateway });
  };

  getCoordinates = (selectedGateway, interval) => {
    let xList = [];
    let yList = [];

    if (!selectedGateway.log) {
      return [
        [interval.start, interval.end],
        [0, 0],
      ];
    } else if (selectedGateway.ratio === 1) {
      return [
        [interval.start, interval.end],
        [1, 1],
      ];
    } else {
      xList.push(interval.start);
      yList.push(selectedGateway.log[0].connected ^ 1);
    }

    xList = xList.concat(selectedGateway.log.map((x) => x.eventDate));

    yList = yList.concat(selectedGateway.log.map((x) => x.connected));

    xList.push(interval.end);
    yList.push(yList[yList.length - 1]);

    return [xList, yList];
  };

  renderKPI = () => {
    const { response, gateways, selectedGateway } = this.state;

    if (response == null) return null;

    const { data, interval } = response;

    const macList = gateways
      .filter((x) => Object.keys(data).includes(x.mac))
      .map((x) => x.mac);

    const coordinates = this.getCoordinates(selectedGateway, interval);

    return (
      <div className="kpi-01-result">
        <div className="kpi-01-table">
          <Table bordered hover size={"sm"}>
            <thead>
              <tr>
                <th>{this.props.t("performance.gateway")}</th>
                <th>{`${this.props.t("performance.interval")} (HH:MM:SS)`}</th>
                <th>{`${this.props.t(
                  "performance.totalTimeOn"
                )} (HH:MM:SS)`}</th>
                <th>{this.props.t("performance.ratio")}</th>
                <th>{this.props.t("performance.connections")}</th>
                <th>{this.props.t("performance.disconnections")}</th>
              </tr>
            </thead>
            <tbody>
              {macList.map((mac, i) => {
                const gwInfo = gateways.filter((x) => x.mac === mac)[0];
                const kpiInfo = data[mac];

                let connectionsStatus;

                if (Object.keys(kpiInfo).length === 0) {
                  connectionsStatus = "no-data";
                } else {
                  connectionsStatus =
                    kpiInfo.ratio > 0.8
                      ? "top"
                      : kpiInfo.ratio > 0.6
                      ? "high"
                      : kpiInfo.ratio > 0.4
                      ? "medium"
                      : kpiInfo.ratio > 0.2
                      ? "low"
                      : "bottom";
                }

                return (
                  <tr
                    key={i}
                    data-type={connectionsStatus}
                    className="gw-kpi-01"
                    onClick={() => {
                      this.selectGateway(i);
                    }}
                  >
                    <td className="kpi-01-gw" id={`gw-tt-${mac}`}>
                      <span>{gwInfo.text}</span>
                      <TooltipButton
                        position="right"
                        target={`gw-tt-${mac}`}
                        text={mac}
                        delay={{ show: 0, hide: 0 }}
                        hideArrow={true}
                      />
                    </td>
                    <td>
                      {utils.secondsToTimeString(kpiInfo.totalTimeSec | 0)}
                    </td>
                    <td>
                      {utils.secondsToTimeString(kpiInfo.totalConnectedSec | 0)}
                    </td>
                    <td>{`${((kpiInfo.ratio || 0) * 100).toFixed(2)}%`}</td>
                    <td>{kpiInfo.connected | 0}</td>
                    <td>{kpiInfo.disconnected | 0}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <ConnectionChart
          title={selectedGateway.text}
          coordinates={coordinates}
          interval={interval}
        />
      </div>
    );
  };

  render() {
    const { gateways } = this.state;

    const selected = gateways.filter((x) => x.checkboxStatus === true).length;

    return (
      <div>
        <div className="kpi-01-header">
          {/* Gateway Selection */}
          <ListDropdown
            toggleClass="ml-0"
            title={`${this.props.t(
              "performance.selectedGateways"
            )}: ${selected}`}
            listItems={[
              {
                text:
                  this.props.t(
                    `performance.${
                      this.state.selectAll ? "selectAll" : "deselectAll"
                    }`
                  ) + "...",
                checkbox: true,
                checkboxStatus: !this.state.selectAll,
                command: () => {
                  let { gateways } = this.state;
                  gateways.forEach(
                    (x) => (x.checkboxStatus = this.state.selectAll)
                  );
                  this.setState({
                    gateways,
                    selectAll: !this.state.selectAll,
                  });
                },
              },

              ...gateways,
            ]}
          />
          {/* Interval Selection */}
          <div className="kpi-01-interval">
            <div>
              <Selector
                className="m-2"
                id="dayOrInterval"
                status={this.state.dayOrInterval}
                items={[
                  this.props.t("performance.date"),
                  this.props.t("performance.interval"),
                ]}
                toggle={() => {
                  let { dayOrInterval, start, end } = this.state;

                  dayOrInterval = !dayOrInterval;

                  if (!dayOrInterval) {
                    end = utils
                      .setOffsetDate(new Date(start), -(60 * 24))
                      .toISOString()
                      .split("T")[0];
                  }

                  this.setState({
                    dayOrInterval,
                    end,
                  });
                }}
                disabled={selected === 0}
                default={false}
              />
            </div>

            <div className="kpi-01-interval-inputs">
              <span className="mr-2 kpi-01-from">
                {this.props.t("performance.from") + ":"}
              </span>
              <input
                type="date"
                className="kpi-01-input-from"
                value={this.state.start}
                onChange={(e) => {
                  this.setState({
                    start: e.target.value,
                    end: !this.state.dayOrInterval
                      ? utils
                          .setOffsetDate(new Date(e.target.value), -60 * 24)
                          .toISOString()
                          .split("T")[0]
                      : this.state.end,
                  });
                }}
                max={
                  this.state.dayOrInterval
                    ? utils
                        .setOffsetDate(new Date(this.state.end), 60 * 24)
                        .toISOString()
                        .split("T")[0]
                    : new Date().toISOString().split("T")[0]
                }
                disabled={selected === 0}
              />
              <span className={`mr-2 kpi-01-to`}>
                {this.props.t("performance.to") + ":"}
              </span>
              <input
                type="date"
                className={`kpi-01-input-to`}
                value={this.state.end}
                onChange={(e) => {
                  this.setState({ end: e.target.value });
                }}
                min={
                  utils
                    .setOffsetDate(new Date(this.state.start), -60 * 24)
                    .toISOString()
                    .split("T")[0]
                }
                max={new Date().toISOString().split("T")[0]}
                disabled={!this.state.dayOrInterval || selected === 0}
              />
            </div>
          </div>
          {/* Action Button */}
          <div>
            <Button
              onClick={this.getKPI}
              color="primary"
              disabled={selected === 0}
            >
              {this.props.t("performance.getPerformance")}
            </Button>
          </div>
        </div>
        {/* Result */}
        {this.renderKPI()}
      </div>
    );
  }
}

export default KPI01;
