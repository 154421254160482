import React, { Component } from "react";
import createPlotlyComponent from "react-plotly.js/factory";

class SmartPlotly extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingPlotly: true,
    }

    import("plotly.js-basic-dist-min").then((PlotlyMin) => {
      this.Plot = createPlotlyComponent(PlotlyMin.default);
      this.setState({ loadingPlotly: false });
    });
  }

  render() {
    if (this.state.loadingPlotly) return null;

    return (
      <this.Plot
        layout={this.props.layout}
        data={this.props.data}
        config={{ displayModeBar: false }}
      />
    );
  }
}

export default SmartPlotly;
