import React from "react"
import { Input } from "reactstrap"

const SearchInput = (props) => {

    return <div className={`d-flex`}>
        <Input
            id={props.id}
            placeholder={props.placeholder}
            type={props.type}
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)} />
    </div>
}

export default SearchInput;