import { SET_CUSTOMERS } from "redux/actions/index";

const initialData = { byId: {}, allIds: [] };

const customers = (state = initialData, action) => {
    switch (action.type) {
        case SET_CUSTOMERS:
            return action.payload;
        default:
            return state;
    }
};

export default customers;