import React, { Component } from "react";
import { Container } from "reactstrap";
import GatewayProperties from "../components/Forms/GatewayProperties";

class EditGatewayProperties extends Component {
  render() {
    const gatewayInfo = this.props.location.gateway;
    if (!gatewayInfo) {
      return "";
    }
    return (
      <>
        <Container fluid className="mt--9">
          <GatewayProperties {...this.props} />
        </Container>
      </>
    );
  }
}

export default EditGatewayProperties;
