import {
  SET_ALERT,
  UPDATE_ALERT_FROM_STREAM,
  REMOVE_ALERT,
} from "../actions/index";

const alerts = (state = { byId: {}, allIds: [], total: -1 }, action) => {
  switch (action.type) {
    case SET_ALERT:
      return (state = action.payload);
    case UPDATE_ALERT_FROM_STREAM:
      /* We will save on the state the thresholds grouped by gateways, devices and variable code */
      const gatewayGuid = action.payload.gatewayGuid;
      const deviceGuid = action.payload.deviceGuid;
      let newTotal = state.total;
      if (newTotal === -1) newTotal = 0;
      let th = action.payload;
      delete th.deviceLastSnapshot;
      delete th.gatewayGuid;
      if (th.alarmType.toUpperCase() === "ON") {
        let newThresholds = state.byId[gatewayGuid]
          ? state.byId[gatewayGuid][deviceGuid] || []
          : [];
        newTotal += 1;
        if (state.byId[gatewayGuid]) {
          /*
           * Gateway already present on allIds
           * There is no need to update allIds
           */
          newThresholds.push(th);
          return {
            ...state,
            total: newTotal,
            byId: {
              ...state.byId,
              [gatewayGuid]: {
                ...state.byId[gatewayGuid],
                [deviceGuid]: newThresholds,
              },
            },
          };
        } else {
          /* Gateway not already present on the list */
          let newAllIds = state.allIds;
          newAllIds.push(gatewayGuid);
          newThresholds.push(th);
          return {
            ...state,
            total: newTotal,
            byId: {
              ...state.byId,
              [gatewayGuid]: {
                ...state.byId[gatewayGuid],
                [deviceGuid]: newThresholds,
              },
            },
            allIds: newAllIds,
          };
        }
      } else {
        let newThresholds = state.byId[gatewayGuid]
          ? state.byId[gatewayGuid][deviceGuid] || []
          : [];
        let newAllIds = state.allIds;
        let idx = -1;
        for (const id in newThresholds) {
          if (newThresholds[id].thresholdGuid === th.thresholdGuid) {
            idx = id;
            break;
          }
        }
        if (idx > -1) {
          newTotal -= 1;
          if (newTotal < 0) newTotal = 0;
          newThresholds.splice(idx, 1);
          if (newThresholds.length === 0) {
            delete state.byId[gatewayGuid][deviceGuid];
            if (Object.values(state.byId[gatewayGuid]).length === 0) {
              delete state.byId[gatewayGuid];
              let idx = newAllIds.indexOf(gatewayGuid);
              if (idx > -1) newAllIds.splice(idx, 1);
            }
            return {
              ...state,
              allIds: newAllIds,
              total: newTotal,
            };
          }

          return {
            ...state,
            total: newTotal,
            byId: {
              ...state.byId,
              [gatewayGuid]: {
                ...state.byId[gatewayGuid],
                [deviceGuid]: newThresholds,
              },
            },
            allIds: newAllIds,
          };
        } else return { ...state, total: newTotal };
      }
    case REMOVE_ALERT:
      let thresholdGuid = action.payload;
      let newState = JSON.parse(JSON.stringify(state));

      let gwI = 0;
      for (let gwGuid of state.allIds) {
        for (let dvGuid of Object.keys(state.byId[gwGuid])) {
          let i = 0;
          for (let alert of state.byId[gwGuid][dvGuid]) {
            if (alert.thresholdGuid === thresholdGuid) {
              newState.byId[gwGuid][dvGuid].splice(i, 1);
              newState.total--;
            }
            i++;
          }
          if (newState.byId[gwGuid][dvGuid].length === 0) {
            delete newState.byId[gwGuid][dvGuid];
          }
        }
        if (Object.values(newState.byId[gwGuid]).length === 0) {
          delete newState.byId[gwGuid];
          newState.allIds.splice(gwI, 1);
        }
        gwI++;
      }

      return newState;
    default:
      return state;
  }
};

export default alerts;
