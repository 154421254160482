import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Plotly from "./Plotly";
import SmartPlotly from "./SmartPlotly";

class Chart extends Component {
  render() {
    if (!this.props.smartChart)
      return (
          <Plotly
            deviceGuid={this.props.deviceGuid}
            gatewayInfo={this.props.gatewayInfo}
            t={this.props.t}
        />
      );
    else
      return (
          <SmartPlotly
            layout={this.props.layout}
            data={this.props.data}
            t={this.props.t}
        />
      );
  }
}

export default withTranslation("common")(Chart);
