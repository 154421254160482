import api from "../services/backendService";
import { HubConnectionBuilder } from "@microsoft/signalr";
import keys from "configs/constants";
import utils from "utils";

import { store } from "../index";
import {
  updateControllersFromSignalR,
  updateSignalRState,
  updateAlertFromSignalR,
  updateLastGatewayStatusFromSignalR,
  updateGatewayFromSignalR,
  setParameterFileList,
} from "../redux/actions/index";

const RETRY_CONNECTION_INTERVAL = 5000;

var sigRConnection;
var retryConnection;

const connectToSignalR = async () => {
  let loginToken;
  let auth = await api.getAPICallAuthHeader();
  if (auth && auth.headers) {
    clearInterval(retryConnection);
    loginToken = auth.headers.Authorization.split(" ")[1];
  } else {
    retryConnection = setInterval(connectToSignalR, RETRY_CONNECTION_INTERVAL);
    return;
  }
  sigRConnection = new HubConnectionBuilder()
    .withUrl(keys.SIGNALR_HUB_URL, {
      accessTokenFactory: () => {
        return loginToken;
      },
    })
    .build();
  sigRConnection.on("broadcastTelemetry", (data) => {
    const storeState = store.getState();

    if (!storeState.signalR.connection) {
      store.dispatch(updateSignalRState({ connection: true }));
    }

    if (storeState.controllers.allIds.length > 0) {
      store.dispatch(updateControllersFromSignalR(data));
    }
  });
  sigRConnection.on("broadcastAlertStatus", (data) => {
    if (!store.getState().signalR.connection) {
      store.dispatch(updateSignalRState({ connection: true }));
    }

    store.dispatch(updateAlertFromSignalR(data));
  });
  sigRConnection.on("broadcastCurrentGatewayStatus", async (data) => {
    if (!store.getState().signalR.connection) {
      store.dispatch(updateSignalRState({ connection: true }));
    }
    store.dispatch(updateLastGatewayStatusFromSignalR(data));
  });
  sigRConnection.on("broadcastGatewayStatus", async (data) => {
    const storeState = store.getState();
    const isAdmin = storeState.user.role === "Admin";

    if (!storeState.signalR.connection) {
      store.dispatch(updateSignalRState({ connection: true }));
    }

    if (
      storeState.gateways.allIds.filter((guid) => guid === data.gatewayGuid)
        .length === 0 &&
      !isAdmin
    ) {
      return;
    }

    let releaseFW = await utils.getGatewayRelease(data.gatewayGuid, isAdmin);

    if (data.status === 1 && data.oldStatus === 0) {
      releaseFW = await utils.getGatewayReleaseAsync(
        data.gatewayGuid,
        isAdmin,
        storeState
      );
    }

    let newData = { ...data };

    newData.releaseFW = releaseFW;

    if (data.lastSeenOn) store.dispatch(updateGatewayFromSignalR(newData));
    else {
      store.dispatch(setParameterFileList(data));
    }
  });
  sigRConnection.onclose(function () {
    utils.debug("Connection to SignalR closed");
    retryConnection = setInterval(connectToSignalR, RETRY_CONNECTION_INTERVAL);
    setTimeout(function () {
      if (!store.getState().signalR.connection) {
        store.dispatch(updateSignalRState({ connection: false }));
      }
    }, RETRY_CONNECTION_INTERVAL + 2000);
  });
  sigRConnection
    .start()
    .then(function () {
      clearInterval(retryConnection);
      utils.debug("Connected to SignalR Service");
      store.dispatch(updateSignalRState({ connection: true }));
    })
    .catch(function (error) {
      utils.debug("SignalR error: " + error.message);
      store.dispatch(updateSignalRState({ connection: false }));
    });
};

const azureSignalR = { connectToSignalR };

export default azureSignalR;
