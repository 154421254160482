import React, { useState } from 'react'
import { Modal, ModalBody, Button, Table } from 'reactstrap'
import InputAndButton from 'components/InputAndButton'
import keys from "configs/constants";
import validator from "validator";
import ListDropdown from 'components/ListDropdown';
import { addUsersToGroup } from 'services/core-iam';
import "./newUsersModal.css";

const NewUsersModal = ({ isOpen, toggle, group, onSuccess, onFailure, t }) => {
    const [emailAddress, setEmailAddress] = useState("");
    const [newUserList, setNewUserList] = useState([]);

    const addUsers = () => {
        const newMembershipsById = group.roles.reduce((acc, role) => {
            acc[role.id] = [];
            return acc;
        }, {});

        newUserList.forEach(newUser => {
            newMembershipsById[newUser.roleId].push(newUser.email);
        });

        const calls = []

        for (const roleId in newMembershipsById) {
            if (newMembershipsById[roleId].length > 0) {
                calls.push(addUsersToGroup(group.id, roleId, newMembershipsById[roleId]));
            }
        }

        Promise.all(calls)
            .then(() => {
                setNewUserList([]);
                setEmailAddress("");
                onSuccess();
            })
            .catch((err) => onFailure(err))
            .finally(closeModal);
    }

    const closeModal = () => {
        toggle();
        setNewUserList([]);
        setEmailAddress("");
    }

    return <Modal
        isOpen={isOpen}
        toggle={closeModal}
        centered>
        <ModalBody>
            <h3 className="mb-2">{t("accessRights.addRegisteredUsers")}</h3>
            <InputAndButton
                id="email-address"
                placeholder={`${t("accessRights.emailAddress")}...`}
                type="email"
                value={emailAddress}
                onChange={(value) => setEmailAddress(value)}
                icon={keys.ICON_ADD}
                onClick={() => {
                    if ((!emailAddress) || (!validator.isEmail(emailAddress))) return;
                    if (newUserList.includes(emailAddress)) return;
                    setNewUserList([...newUserList, { email: emailAddress, roleId: group.roles[0].id }]);
                    setEmailAddress("");
                }} />
            <div className="ar-email-list">
                <Table>
                    <thead>
                        <tr>
                            <th>{t("accessRights.emailAddress")}</th>
                            <th>{t("accessRights.roleName")}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody className="new-user-list">
                        {newUserList.map((newUser, i) => {
                            return <tr key={i}>
                                <td>{newUser.email}</td>
                                <td>
                                    <ListDropdown
                                        toggleClass="new-user-role-dropdown"
                                        listItems={group.roles.map((x) => {
                                            return {
                                                text: x.name,
                                                value: x.id,
                                                command: () => {
                                                    const newUserListCopy = [...newUserList];
                                                    newUserListCopy[i].roleId = x.id;
                                                    setNewUserList(newUserListCopy);
                                                }
                                            }
                                        })}
                                        title={group.roles.find(x => x.id === newUser.roleId).name} />
                                </td>
                                <td className="new-user-remove" onClick={() => setNewUserList(newUserList.filter(x => x.email !== newUser.email))}>
                                    {keys.ICON_TRASH}
                                </td>
                            </tr>
                        })}
                    </tbody>
                </Table>
            </div>
            <Button
                className="float-right"
                color="primary"
                disabled={newUserList.length === 0}
                onClick={addUsers}>
                {newUserList.length === 1
                    ? t("accessRights.addUserToGroup")
                    : `${t("accessRights.addUsersToGroup")} ${newUserList.length > 1
                        ? `(${newUserList.length})`
                        : ""}`}
            </Button>
        </ModalBody>
    </Modal>
}

export default NewUsersModal;