import React from "react";
import { Table, Button } from "reactstrap";

import keys from "configs/constants";
import { withTranslation } from "react-i18next";

class AdminDtcFileTable extends React.Component {
  onRemoveClicked(data) {
    this.props.deleteDSCFile(data);
  }

  renderRows() {
    const t = this;
    if (this.props.data.length > 0) {
      return this.props.data.map((item, idx) => {
        return (
          <tr key={idx}>
            <td>{item.model}</td>
            <td>
              {(item.family || "na") +
                "-" +
                (item.version || "na") +
                "-" +
                (item.e2 || "na")}
            </td>
            <td className="text-center">
              <div
                className={
                  item.gwdsc
                    ? "icon icon-sm icon-shape bg-success text-white"
                    : "icon icon-sm icon-shape bg-danger text-white"
                }
              >
                {item.gwdsc.state ? keys.ICON_WEL_ALLGOOD : keys.ICON_WEL_BAD}
              </div>
            </td>
            <td className="text-center">
              <div
                className={
                  item.wel.state
                    ? "icon icon-sm icon-shape bg-success text-white"
                    : "icon icon-sm icon-shape bg-danger text-white"
                }
              >
                {item.wel.state ? keys.ICON_WEL_ALLGOOD : keys.ICON_WEL_BAD}
              </div>
            </td>
            <td className="text-center">
              <Button
                onClick={t.onRemoveClicked.bind(t, {
                  dtc: item.guid,
                  drc: item.gwdsc.guid,
                  wel: item.wel.wGuid,
                  welT: item.wel.tGuid,
                })}
              >
                {this.props.t("deviceDescriptor.remove")}
              </Button>
            </td>
          </tr>
        );
      });
    } else return;
  }

  render() {
    return (
      <div className="border" color="primary">
        <Table className="align-items-center" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">{this.props.t("deviceDescriptor.model")}</th>
              <th scope="col">{this.props.t("deviceDescriptor.famVerE2")}</th>
              <th scope="col" className="text-center">
                {this.props.t("deviceDescriptor.gwDescriptor")}
              </th>
              <th scope="col" className="text-center">
                {this.props.t("deviceDescriptor.welDescriptor")}
              </th>
              <th scope="col" className="text-center">
                {this.props.t("deviceDescriptor.action")}
              </th>
            </tr>
          </thead>
          <tbody>{this.renderRows()}</tbody>
        </Table>
      </div>
    );
  }
}

export default withTranslation("common")(AdminDtcFileTable);
