import { UPDATE_SIGNALR_STATE } from "../actions/index";
import { Store as notifyStore } from "react-notifications-component";
import {
  tempOptions,
  //permanentOptions,
  NotifyContent,
} from "../../components/Notify";

import { t } from "i18next";

let firstConnection = false;

const signalR = (state = { connection: false }, action) => {
  switch (action.type) {
    case UPDATE_SIGNALR_STATE:
      if (
        state.connection !== action.payload.connection &&
        firstConnection === true
      ) {
        notifyStore.addNotification({
          ...tempOptions,
          content: NotifyContent(
            action.payload.connection === true ? "success" : "danger",
            "Data Streaming",
            action.payload.connection === true
              ? t("common:signalR.connectionOn")
              : t("common:signalR.connectionOff")
          ),
        });
      }
      firstConnection = true;
      return { ...state, connection: action.payload.connection };
    default:
      return state;
  }
};

export default signalR;
