import {
  SET_CONTROLLERS,
  UPDATE_CONTROLLER_POINTS,
  RESET_CONTROLLERS,
  CONTROLLERS_LOADED,
  UPDATE_CONTROLLER_DETAILS,
} from "../actions/index";

const initialData = { byId: {}, allIds: [] };

const controllers = (state = initialData, action) => {
  const { payload } = action;
  switch (action.type) {
    case SET_CONTROLLERS:
      return action.payload;
    case UPDATE_CONTROLLER_POINTS:
      if (state.byId === undefined) return state;
      if (!state.byId[payload.deviceGuid]) return state;
      if (!state.byId[payload.deviceGuid].pointsById) return state;

      let pointsIds = Object.getOwnPropertyNames(
        state.byId[payload.deviceGuid].pointsById
      );
      let pointByIdObj = state.byId[payload.deviceGuid].pointsById;
      for (let p of pointsIds) {
        for (let n of payload.points) {
          if (n.name.toUpperCase() === p.toUpperCase()) {
            pointByIdObj[p].value = n.value;
            pointByIdObj[p].date = payload["date"];
            continue;
          }
        }
      }
      const newState = {
        ...state,
        byId: {
          ...state.byId,
          [payload.deviceGuid]: {
            ...state.byId[payload.deviceGuid],
            pointsById: pointByIdObj,
          },
        },
      };
      return newState;
    case UPDATE_CONTROLLER_DETAILS:
      let controllers = state;
      controllers.byId[payload.deviceGuid].friendlyName = payload.friendlyName;
      controllers.byId[payload.deviceGuid].cabinetSerial = payload.cabinetSerial;
      return controllers;
    case RESET_CONTROLLERS:
      return initialData;
    default:
      return state;
  }
};

export const controllersLoaded = (state = false, action) => {
  switch (action.type) {
    case CONTROLLERS_LOADED:
      return action.payload;
    default:
      return state;
  }
};

export default controllers;
