import React, { useState } from 'react'
import { Modal, ModalBody, Button, Input } from 'reactstrap'
import api from 'services/backendService'
import utils from 'utils';

const AddLicenseModal = ({ 
    isOpen = false, 
    closeModal = () => {}, 
    organizationGuids = [], 
    setLoader = (_) => {}, 
    refresh = async () => {}, 
    t = () => {} }) => {
    const [activationCode, setActivationCode] = useState("");

    const onClose = () => {
        setActivationCode("");
        closeModal();
    };

    const assignLicensesToOrganizations = () => {
        // Disable activation code
        setLoader(true);
        onClose();
        api.disableActivationCode(activationCode)
            .then(async (response) => {
                // Assign licenses to organizations
                const { licenses } = response.data;
                const licenseGuids = licenses.map((l) => l.licenseGuid);
                await api.addLicenseToSubscription(organizationGuids, licenseGuids);
                await refresh();
            })
            .catch((error) => {
                utils.debug(error, utils.MSG_TYPE_WARN);
            })
            .finally(() => {
                setLoader(false);
            });
    };

    return (
        <Modal isOpen={isOpen} toggle={onClose} centered>
            <ModalBody>
                <h3>{t("license.enterActivationCode") + ":"}</h3>
                <Input
                    className="mt-4"
                    type="text"
                    placeholder={t("license.activationCode")} 
                    value={activationCode}
                    onChange={(e) => setActivationCode(e.target.value)}/>
                <Button 
                    color="primary" 
                    className="float-right mt-4"
                    onClick={assignLicensesToOrganizations}
                    disabled={activationCode.trim().length !== 36}>
                    {t("license.addLicense")}
                </Button>
            </ModalBody>
        </Modal>
    )
}

export default AddLicenseModal