import React, { Component } from "react";
import { Button, Input } from "reactstrap";
import "./style.css";

class NumberSelector extends Component {
  render() {
    return (
      <div className="num-selector-container">
        <Button
          className="m-0 mr-1"
          size={this.props.size}
          color={this.props.color}
          onClick={this.props.onDecrement}
        >
          -
        </Button>
        <Input
          className="m-0"
          bsSize={this.props.size}
          value={this.props.value}
          onChange={this.props.onChange}
        />
        <Button
          className="m-0 ml-1"
          size={this.props.size}
          color={this.props.color}
          onClick={this.props.onIncrement}
        >
          +
        </Button>
      </div>
    );
  }
}

NumberSelector.defaultProps = {
  size: "md",
  color: "secondary",
};

export default NumberSelector;
