import React, { Component } from "react";
import { ResetPasswordPolicy, msalAuth } from "../services/backendService";
import { getSelfGroups } from "./core-iam";
import Loader from "../components/Loader";
import sigR from "services/azureSignalR";
import utils from "utils";
import { AuthorizationContext } from "../contexts/AuthorizationContext";

// This is High Order Component (HOC) that wraps the App component
export function withADB2CAuth(AppComponent) {
  return class extends Component {
    static contextType = AuthorizationContext;
    displayName = "withADB2CAuthHOC";
    state = {
      isAuthenticated: false,
      user: {},
      renewIframe: false,
      hasError: false,
      errorMessage: null,
      signOut: false,
    };

    _userAccount = null;

    onSignIn() {
      msalAuth.loginRedirect({});
    }

    onSignOut() {
      this.setState({ isAuthenticated: false, user: {}, signOut: true });
      utils.debug("azureADB2C -- Signout Called");
      msalAuth.logout();
    }

    // Check if the current path is one of the unauthenticated paths
    isUnauthPath = () => {
      return window.location.pathname.includes("/capp");
    };

    async componentDidMount() {
      this._userAccount = msalAuth.getAccount();

      try {
        this._userAccount.idToken.settings = JSON.parse(
          this._userAccount.idToken.settings
        );
      } catch (e) {}

      msalAuth.handleRedirectCallback((error, response) => {
        if (error !== null) {
          // on fail
          utils.debug("azureADB2C -- authErr: " + error);
          // check if error code for forgot password is there
          if (error.errorMessage.indexOf("AADB2C90118") > -1) {
            // change authority to password reset policy
            msalAuth.loginRedirect({ authority: ResetPasswordPolicy });
          }
          utils.debug("azureADB2C -- Error: " + JSON.stringify(error));
          this.setState({
            hasError: true,
            errorMessage: error.errorMessage,
          });
        } else {
          utils.debug("azureADB2C -- Redirect Callback");
          this._userAccount = msalAuth.getAccount();
          try {
            this._userAccount.idToken.settings = JSON.parse(
              this._userAccount.idToken.settings
            );
          } catch (e) {}

          if (
            this._userAccount.idToken.acr.toUpperCase() === "B2C_1A_PROFILEEDIT"
          ) {
            window.alert(
              "User Profile has been updated correctly, you will be redirected to the login page"
            );
            this.onSignOut();
          } else {
            window.sessionStorage.setItem("userGUID", response.uniqueId);
            this.setState({
              user: this._userAccount,
            });
          }
        }
      });

      if (this.isUnauthPath()) {
        return;
      }

      if (!msalAuth.getLoginInProgress()) {
        if (!this._userAccount) {
          utils.debug("azureADB2C -- Login required...");
          if (!this.state.signOut) {
            msalAuth.loginRedirect({});
          }
        } else {
          if (msalAuth.account.idToken.acr === "b2c_1a_passwordreset") {
            utils.debug(
              "azureADB2C -- Login required after password update..."
            );
            this.onSignOut();
            return;
          }
          utils.debug("azureADB2C -- Login NOT required");
          await sigR.connectToSignalR();
          window.sessionStorage.setItem(
            "userGUID",
            this._userAccount.accountIdentifier
          );

          try {
            const groups = await getSelfGroups();
            this.context.setGroups(groups);
          } catch (e) {
            utils.debug("Failed to fetch groups for the user: " + e);
          }

          this.setState({
            isAuthenticated: true,
            user: this._userAccount,
            signOut: false,
          });
        }
      }
    }

    render() {
      if (this.state.renewIframe) {
        return <div>hidden renew iframe - not visible</div>;
      }

      if (!this.state.isAuthenticated && !this.state.hasError) {
        if (this.isUnauthPath()) {
          return <AppComponent {...this.props} />;
        }
      }
      if (this.state.isAuthenticated) {
        if (
          window.location.pathname.includes("/ad") &&
          this.state.user.idToken.role !== "Admin"
        ) {
          // Redirect if a not Admin user is trying to access
          // one of the Admin pages
          window.location.replace(
            window.location.protocol +
              "//" +
              window.location.hostname +
              ":" +
              window.location.port
          );
        }

        return (
          <AppComponent
            auth={this.state}
            onSignIn={() => this.onSignIn()}
            onSignOut={() => this.onSignOut()}
            {...this.props}
          />
        );
      }

      if (utils.isDevEnvironment()) {
        return <div>{this.state.errorMessage}</div>;
      }

      return <Loader />;
    }
  };
}
