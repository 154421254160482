import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  InputGroup,
  InputGroupText,
  Input,
  Button,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import keys from "configs/constants";
import "./style.css";
import utils, { checkMac } from "utils";

class RegisterNewGateway extends Component {
  render() {
    const validMac = utils.isMACAddressValid(this.props.value);

    const isValid =
      validMac === checkMac.VALID_MAC ||
      !this.props.value ||
      this.props.value === "";

    let message = "valid";

    switch (validMac) {
      case checkMac.INVALID_LENGTH:
        message = "registerThing.invalidLength";
        break;
      case checkMac.INVALID_CHARS:
        message = "registerThing.invalidChars";
        break;
      case checkMac.INVALID_OUI:
        message = "registerThing.invalidOui";
        break;

      default:
        break;
    }

    return (
      <Modal centered isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <ModalBody>
          <div className="mb-2">
            {this.props.t("gatewayTable.enterMacAddress") + ":"}
          </div>
          <InputGroup>
            <InputGroupText className="bg-gray text-white">
              {keys.ICON_ADD}
            </InputGroupText>
            <Input
              className={`mac-address-input ${
                isValid ? "" : "border border-danger"
              }`}
              placeholder={this.props.t("gatewayProperties.macAddress")}
              value={this.props.value || ""}
              onChange={this.props.onChange}
            />
          </InputGroup>
          <div className={`text-danger mt-2 ${isValid ? "invisible" : ""}`}>
            {this.props.t(message)}
          </div>
          <Button
            color="primary"
            className="float-right mt-4"
            onClick={() => {
              this.props.toggle();
              window
                .open(
                  `${
                    window.location.origin
                  }/tr?duid=${this.props.value.toUpperCase()}${
                    this.props.siteGuid
                      ? `&siteGuid=${this.props.siteGuid}`
                      : ""
                  }`,
                  "_self",
                  `${JSON.stringify(this.props)}`
                )
                .focus();
            }}
            disabled={validMac !== checkMac.VALID_MAC}
          >
            {this.props.t("registerThing.registerIt") + "!"}
          </Button>
        </ModalBody>
      </Modal>
    );
  }
}

export default withTranslation("common")(RegisterNewGateway);
