import React, { useState } from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import { removeUserFromGroup } from 'services/core-iam';
import './removeUsersModal.css'
import Loader from "../Loader";

const RemoveUsersModal = ({ isOpen, toggle, onAbort, group, usersToRemove, onSuccess, onFailure, t }) => {
    const [isLoading, setIsLoading] = useState(false);

    const onConfirm = () => {
        setIsLoading(true);
        removeUserFromGroup(group.id, usersToRemove.map(x => x.email), false, true)
            .then(onSuccess)
            .catch((err) => onFailure(err))
            .finally(() => {
                setIsLoading(false);
                toggle();
            })
    }

    return group && <Modal
        isOpen={isOpen}
        toggle={toggle}
        centered>
        <ModalBody>
            {isLoading && <Loader />}
            <div className='remove-users-list mb-2  rounded '>
                <h3> {t("accessRights.confirmRemoveUsers")}</h3>
                <h3> {`${group.name} ?`} </h3>
            </div>
            <div className='remove-users-list my-3 p-2 border rounded'>
                {usersToRemove.map((user, i) =>
                    <div key={i} className="d-flex justify-content-between align-items-center p-2">
                        <h4 className="mb-0 d-inline">
                            {user.firstName} {user.lastName}
                        </h4>
                        <small className="ml-0">{user.email}</small>
                    </div>)}
            </div>
            <div className='d-flex justify-content-center mt-2'>
                <Button color="primary" onClick={onAbort}> {t("accessRights.abort")} </Button>
                <Button color="primary" onClick={onConfirm} className="ml-2"> {t("accessRights.delete")} </Button>
            </div>
        </ModalBody>
    </Modal>
}

export default RemoveUsersModal;