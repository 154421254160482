import React, { Component } from "react";
import { ModalBody, Input, Label, FormGroup, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import ListDropdown from "../../components/ListDropdown";
import { TooltipButton } from "../../components/Tooltip";
import api from "../../services/backendService";
import icons from "../../configs/constants";
import { Store as notifyStore } from "react-notifications-component";
import { tempOptions, NotifyContent } from "components/Notify";
import ReportThresholds from "./ReportThresholds";
import "./reports.css";

class ScheduledReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scheduledReportEnabled: false,
      scheduledReportTime: "00:00",
      scheduledReportEmails: [],
      scheduledReportType: "HACCP",
      emailError: false,
      alarmUp: "3.0",
      warningUp: "5.0",
      warningDown: "-3.0",
      alarmDown: "-5.0",
      thresholdsError: false,
    };
  }

  componentDidMount = async () => {
    try {
      const report = await api.getSiteAutomaticReport(this.props.site.siteGuid);
      if (report) {
        const scheduledReportTime = new Date(new Date().toISOString().split('T')[0] + "T" + report.data.time + "Z")
          .toTimeString().substring(0, 5);
        this.setState({
          scheduledReportEnabled: report.data.isEnabled,
          scheduledReportTime: scheduledReportTime,
          scheduledReportEmails: report.data.emailList,
          scheduledReportType: report.data.reportType,
          alarmUp: report.data.fqrSettings?.alarmUp ?? null,
          warningUp: report.data.fqrSettings?.warningUp ?? null,
          warningDown: report.data.fqrSettings?.warningDown ?? null,
          alarmDown: report.data.fqrSettings?.alarmDown ?? null,
          thresholdsError: false,
        });
      }
    } catch (e) { }
  };

  toggleScheduledReport = () => {
    this.setState((prevState) => ({
      scheduledReportEnabled: !prevState.scheduledReportEnabled,
    }));
  };

  handleScheduledReportTimeChange = (e) => {
    this.setState({ scheduledReportTime: e.target.value });
  };

  handleScheduledReportEmailsChange = (e) => {
    const emails = e.target.value.split(";");
    const emailError = this.validateEmails(emails);
    this.setState({ scheduledReportEmails: emails, emailError });
  };

  validateEmails = (emails) => {
    if (!emails) return false;
    const emailArray = emails.map((email) => email.trim());
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailArray.some((email) => !emailRegex.test(email));
  };

  handleScheduledReportTypeChange = (type) => {
    this.setState({ scheduledReportType: type });
  };

  async onSaveChangesClicked(instantSend = false) {
    const time = this.state.scheduledReportTime.split(':');
    let hours = Number.parseInt(time[0]) + (new Date().getTimezoneOffset() / 60);
    hours = (hours + 24) % 24;
    const minutes = time[1];
    const fqrSettings = this.state.scheduledReportType === "HACCP" ? null : {
          alarmUp:  Number.parseFloat(this.state.alarmUp) ,
          warningUp: Number.parseFloat(this.state.warningUp),
          warningDown: Number.parseFloat(this.state.warningDown),
          alarmDown: Number.parseFloat(this.state.alarmDown),
        };
    
    try {
      const siteReport = {
        siteGuid: this.props.site.siteGuid,
        isEnabled: this.state.scheduledReportEnabled,
        time: `${hours.toString().padStart(2, '0')}:${minutes}`,
        emailList: this.state.scheduledReportEmails.map(email => email.trim()),
        reportType: this.state.scheduledReportType,
        fqrSettings,
        instantSend,
      };
      await api.upsertSiteAutomaticReport(siteReport);
      notifyStore.addNotification({
        ...tempOptions,
        content: NotifyContent(
          "success",
          this.state.scheduledReportEnabled ?
            this.props.t("report.reportSuccess") :
            this.props.t("report.reportDisabledSuccess")
        )
      })
    } catch (e) {
      notifyStore.addNotification({
        ...tempOptions,
        content: NotifyContent("danger", this.props.t("report.reportFail"))
      })
    }

    this.props.toggle();
  }

  attributeDetails = (id, name, description) => {
    return (
      <span className="d-flex">
        <span className="report-label">{name}</span>
        <span id={`attr-${id}`} className="card-point">{icons.ICON_INFO}</span>
        {description && <TooltipButton target={`attr-${id}`} text={description} />}
      </span>
    );
  }

  render() {
    const {
      scheduledReportEnabled,
      scheduledReportTime,
      scheduledReportEmails,
      scheduledReportType,
      emailError,
      thresholdsError
    } = this.state;

    const reportTypeList = [
      { text: "HACCP", command: () => this.handleScheduledReportTypeChange("HACCP") },
      { text: "FQR", command: () => this.handleScheduledReportTypeChange("FQR") }
    ];

    const isSaveButtonDisabled =
      !scheduledReportEnabled ? false :
        scheduledReportEmails.length === 0
        || emailError
        || scheduledReportTime.length === 0
        || thresholdsError;

    return (
      <ModalBody>
        <div className="report-conf">
          <FormGroup check inline className="mb-2">
            <Label check>
              <Input
                type="checkbox"
                checked={scheduledReportEnabled}
                onChange={this.toggleScheduledReport}
                className="scheduled-report-checkbox"
              />
              {this.props.t("report.enableScheduledReport")}
            </Label>
          </FormGroup>
          <div className={`scheduled-report-grid-container`}>
            <span className="m-1">
              {this.attributeDetails('report-type', this.props.t("report.reportType"), this.props.t("report.reportTypeDescription"))}
              <ListDropdown
                className="scheduled-report-output-dropdown"
                toggleClass="m-0"
                title={scheduledReportType}
                listItems={reportTypeList}
                disabled={!scheduledReportEnabled || !this.props.fqrEnabled}
              />
            </span>
            <span className="m-1">
              {this.attributeDetails('report-time', this.props.t("report.reportTime"), this.props.t("report.reportTimeDescription"))}
              <Input
                type="time"
                value={scheduledReportTime}
                onChange={this.handleScheduledReportTimeChange}
                className="scheduled-report-time-input"
                disabled={!scheduledReportEnabled}
              />
            </span>
          </div>
          <div>
            {
              scheduledReportType === "FQR" && (
                <ReportThresholds
                  alarmUp={this.state.alarmUp}
                  warningUp={this.state.warningUp}
                  warningDown={this.state.warningDown}
                  alarmDown={this.state.alarmDown}
                  isReport={true}
                  foodQuality={1}
                  onInputChange={(e) => {
                    for (const key in e) {
                      this.setState({ [key]: e[key] });
                    }
                  }}
                  t={this.props.t} />
              )
            }
          </div>

          <div className={`scheduled-report-container`}>
            {this.attributeDetails('report-email-addresses', this.props.t("report.emailAddresses"), this.props.t("deviceThresholds.multipleReceivers"))}
            <Input
              type="text"
              value={scheduledReportEmails.join(";")}
              onChange={this.handleScheduledReportEmailsChange}
              disabled={!scheduledReportEnabled}
              placeholder={this.props.t("alarmFastSetup.multipleReceiversPlaceHolder")}
            />
            {emailError && (
              <span className="text-danger">
                {this.props.t("report.invalidEmailAddresses")}
              </span>
            )}
          </div>
          <UncontrolledDropdown group>
            <Button
              color="primary"
              onClick={() => {
                this.onSaveChangesClicked(false);
              }}
              disabled={isSaveButtonDisabled}
            >
              {this.props.t("report.save")}
            </Button>
            <DropdownToggle 
                disabled={isSaveButtonDisabled}
                caret
                color="primary"
            >{``}</DropdownToggle>
            <DropdownMenu 
                className="report-btn-sub">
              <DropdownItem
                disabled={isSaveButtonDisabled}
                onClick={() => {
                  this.onSaveChangesClicked(true);
                }}
                >
                {this.props.t("report.saveAndSend")}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </ModalBody>
    );
  }
}

export default ScheduledReports;
