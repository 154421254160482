import React, { Component } from "react";
import "./style.css";

class KPIBox extends Component {
  state = {};
  render() {
    return (
      <div className="kpi-container border border-light rounded">
        <h4>{this.props.title}</h4>
        {this.props.children}
      </div>
    );
  }
}

export default KPIBox;
