import React, { Component } from "react";
import ListDropdown from "../ListDropdown";

export class LicenseListFilters extends Component {
  render() {
    let gatewayListItems = [];
    const siteListItems = Object.keys(this.props.siteList).map((siteGuid) => {
      let site = this.props.siteList[siteGuid];

      Object.keys(site.gateways).forEach((gatewayGuid) => {
        gatewayListItems.push(site.gateways[gatewayGuid]);
      });
      return site;
    });

    const nSelectedSites =
      siteListItems.filter((s) => s.checkboxStatus === true).length === 0
        ? this.props.t("license.all").toLowerCase()
        : siteListItems.filter((s) => s.checkboxStatus === true).length;

    const nSelectedGateways =
      gatewayListItems.filter((g) => g.checkboxStatus === true).length === 0
        ? this.props.t("license.all").toLowerCase()
        : gatewayListItems.filter((g) => g.checkboxStatus === true).length;

    const nFilters =
      this.props.filtersItems.filter((f) => f.checkboxStatus === true)
        .length === 0
        ? this.props.t("license.none").toLowerCase()
        : this.props.filtersItems.filter((f) => f.checkboxStatus === true)
            .length;

    return (
      <div className="mb-4 d-flex justify-content-between" id={this.props.id}>
        <div className="license-filters">
          <ListDropdown
            toggleClass="m-0 mr-4 license-filter-modal-toggle"
            toggleMenu="license-filter-menu"
            title={
              this.props.t("license.selectSites") + " (" + nSelectedSites + ")"
            }
            listItems={siteListItems}
          />
          <ListDropdown
            toggleClass="m-0 mr-4 license-filter-modal-toggle"
            toggleMenu="license-filter-menu"
            title={
              this.props.t("license.selectGateways") +
              " (" +
              nSelectedGateways +
              ")"
            }
            listItems={gatewayListItems}
          />
          <ListDropdown
            toggleClass="m-0 license-filter-modal-toggle"
            toggleMenu="license-filter-menu"
            title={this.props.t("license.filters") + " (" + nFilters + ")"}
            listItems={this.props.filtersItems}
          />
        </div>
      </div>
    );
  }
}
