import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardHeader,
  Form,
  FormGroup,
  InputGroup,
  InputGroupText,
  Input,
  Button,
} from "reactstrap";

import SelectSearch, { fuzzySearch } from "react-select-search";

import keys from "configs/constants";
import api from "services/backendService";
import { withTranslation } from "react-i18next";

class SitesAdmin extends React.Component {
  state = {
    dataOptions: [],
    customers: [],
    customerGuid: null,
    selected: false,
    createNew: false,
    errorState: "",
    name: "",
    country: "",
    total: 0,
  };

  async componentDidMount() {
    const pload = await api.getSitesDetails();
    const ploadc = await api.getCustomersDetails();
    this.setState({
      dataOptions: pload.data.data,
      customers: ploadc.data.data,
      total: pload.data.total,
    });
  }

  onInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  }

  async reloadData(pl) {
    if (!pl.message && (pl.status === 200 || pl.status === 201)) {
      const pload = await api.getSitesDetails();
      if (pload.message === 500) {
        this.setState({
          dataOptions: pload.data.data,
          response: "Internal Server Error",
          errorState: "Error",
        });
        return;
      }
      this.setState({
        dataOptions: pload.data.data,
        response: pl,
        errorState: "OK",
        total: pload.data.total,
      });
    } else this.setState({ response: pl.message, errorState: "Error" });
  }

  async addData() {
    const pload = await api.addSite(
      this.state.name.trim(),
      this.state.country,
      this.state.city,
      parseFloat(this.state.latitude),
      parseFloat(this.state.longitude),
      this.state.customerGuid
    );
    await this.reloadData(pload);
  }

  async deleteData() {
    const pload = await api.deleteSite(this.state.selectedGuid, true);
    await this.reloadData(pload);
  }

  async updateData() {
    const pload = await api.updateSite(
      this.state.name.trim(),
      this.state.country,
      this.state.city,
      parseFloat(this.state.latitude),
      parseFloat(this.state.longitude),
      this.state.selectedGuid,
      this.state.customerGuid
    );
    await this.reloadData(pload);
  }

  onCustomerSelected(cGuid) {
    this.setState({ customerGuid: cGuid });
  }

  async onDataSelected(idx) {
    if (idx === 0) {
      this.setState({
        createNew: true,
        selected: true,
        response: null,
        selectedGuid: this.state.dataOptions[idx].siteGuid,
        selectedIdx: idx,
        name: "",
        country: "",
        city: "",
        latitude: "",
        longitude: "",
        customerGuid: "",
        errorState: "",
      });
    } else {
      const pload = await api.getSiteDetails(
        this.state.dataOptions[idx].siteGuid
      );
      this.setState({
        createNew: false,
        selected: true,
        response: null,
        selectedGuid: this.state.dataOptions[idx].siteGuid,
        selectedIdx: idx,
        name: this.state.dataOptions[idx].name,
        country: this.state.dataOptions[idx].country,
        city: this.state.dataOptions[idx].city,
        latitude: this.state.dataOptions[idx].latitude,
        longitude: this.state.dataOptions[idx].longitude,
        customerGuid: pload.data.customer.customerGuid,
        errorState: "",
      });
    }
  }

  buttonsToRender() {
    if (this.state.createNew) {
      return (
        <div className="text-center">
          <Button
            className="mt-4"
            color="primary"
            type="button"
            onClick={this.addData.bind(this)}
          >
            {this.props.t("sitesAdmin.createSite")}
          </Button>
        </div>
      );
    } else {
      return (
        <div className="text-center">
          <Button
            className="mt-4"
            color="primary"
            type="button"
            onClick={this.updateData.bind(this)}
          >
            {this.props.t("sitesAdmin.updateSite")}
          </Button>
          <Button
            className="mt-4"
            color="primary"
            type="button"
            onClick={this.deleteData.bind(this)}
          >
            {this.props.t("sitesAdmin.deleteSite")}
          </Button>
        </div>
      );
    }
  }

  formToRender() {
    const customerOptions = this.state.customers.map((customer) => ({
      label: customer.name,
      value: customer.customerGuid,
      name: customer.name + "  [" + customer.customerGuid + "]",
    }));

    const renderButtons = this.buttonsToRender();

    const formText = this.state.createNew
      ? this.props.t("sitesAdmin.enterSiteInfo") +
      ' "' +
      this.props.t("sitesAdmin.createSite") +
      '"'
      : this.props.t("sitesAdmin.editSiteInfo");
    return (
      <Form role="form">
        <div className="text-center">
          {formText}
          <br />
          <br />
        </div>
        <FormGroup>
          <InputGroup className="mb-3">
            <InputGroupText>{keys.ICON_SITE}</InputGroupText>
            <Input
              placeholder={this.props.t("sitesAdmin.name")}
              name="name"
              type="text"
              onChange={this.onInputChange.bind(this)}
              value={this.state.name}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroupText>{keys.ICON_SITE}</InputGroupText>
            <Input
              placeholder={this.props.t("sitesAdmin.country")}
              name="country"
              type="text"
              onChange={this.onInputChange.bind(this)}
              value={this.state.country}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroupText>{keys.ICON_SITE}</InputGroupText>
            <Input
              placeholder={this.props.t("sitesAdmin.city")}
              name="city"
              type="text"
              onChange={this.onInputChange.bind(this)}
              value={this.state.city}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroupText>{keys.ICON_SITE}</InputGroupText>
            <Input
              placeholder={this.props.t("sitesAdmin.latitude")}
              name="latitude"
              type="number"
              onChange={this.onInputChange.bind(this)}
              value={this.state.latitude}
            />
            <InputGroupText>{keys.ICON_SITE}</InputGroupText>
            <Input
              placeholder={this.props.t("sitesAdmin.longitude")}
              name="longitude"
              type="number"
              onChange={this.onInputChange.bind(this)}
              value={this.state.longitude}
            />
          </InputGroup>
          <div className="mb-3 d-flex">
            <InputGroupText>{keys.ICON_CUSTOMER}</InputGroupText>
            <SelectSearch
              filterOptions={fuzzySearch}
              options={customerOptions}
              name="customer"
              placeholder={this.props.t("sitesAdmin.addToCustomer") + "..."}
              value={this.state.customerGuid}
              search={true}
              onChange={this.onCustomerSelected.bind(this)}
              autoComplete="on"
            />
          </div>
        </FormGroup>
        {renderButtons}
        <br />
        <br />
        {this.state.errorState === ""
          ? ""
          : this.props.t("sitesAdmin.thisIsTheResponse") +
          " (" +
          this.state.errorState +
          ")"}
        <br />
        <br />
        <div className="border">
          {this.state.response !== null
            ? JSON.stringify(this.state.response)
            : ""}
        </div>
      </Form>
    );
  }

  render() {
    var dataOpt = this.state.dataOptions;
    if (dataOpt.length === 0 || dataOpt[0].siteGuid !== "0") {
      dataOpt.unshift({
        siteGuid: "0",
        name: this.props.t("sitesAdmin.createNewSite") + "...",
      });
    }

    const selectOptions = dataOpt.map((data, key) => ({
      label: data.name,
      value: key,
      name:
        data.siteGuid === "0"
          ? data.name
          : data.name + "  [" + data.siteGuid + "]",
    }));

    const renderForm = this.state.selected ? this.formToRender() : "";

    return (
      <>
        <Container fluid className="mt--9">
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader>
                  <div className="w-50">
                    <SelectSearch
                      filterOptions={fuzzySearch}
                      options={selectOptions}
                      name="customer"
                      placeholder={
                        this.props.t("sitesAdmin.selectSite") +
                        "... (" +
                        this.props.t("sitesAdmin.total") +
                        ": " +
                        this.state.total +
                        ")"
                      }
                      search={true}
                      onChange={this.onDataSelected.bind(this)}
                      autoComplete="on"
                    />
                  </div>
                </CardHeader>
                <CardBody
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {renderForm}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withTranslation("common")(SitesAdmin);
