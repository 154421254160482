/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

import Plant from "../components/Plant";

import {
  setSites,
  resetControllers,
  setControllersLoaded,
} from "../redux/actions/index";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import utils from "utils";

class Plants extends React.Component {
  componentDidMount() {
    this.props.setControllersLoaded(false);
    this.props.resetControllers();
  }

  render() {
    let autoRedirect = false;
    if (this.props.store.sites.allIds.length === 1) {
      autoRedirect = true;
    }

    const sites = Object.values(this.props.store.sites.byId);

    return (
      <>
        {/* Page content */}
        <Container fluid className="mt--9">
          {/* Table */}
          <Row>
            {sites.map((k) => {
              var alStatus = "ok";
              for (const gw of k.gateways) {
                if (
                  this.props.store.alerts.byId[gw.gatewayGuid] &&
                  Object.values(this.props.store.alerts.byId[gw.gatewayGuid])
                    .length > 0
                ) {
                  alStatus = "alarm";
                  break;
                }
              }

              const gatewayList = utils.getGatewaysForSite(
                k.siteGuid,
                this.props.store.gateways.byId
              );

              return (
                <Col
                  key={k.siteGuid}
                  className="order-lg-2 pt-lg-5 mb-5 mb-lg-0"
                  xs="12"
                  sm="6"
                  lg="4"
                  xl="3"
                >
                  <Plant
                    key={k.siteGuid}
                    siteGuid={k.siteGuid}
                    gatewayList={gatewayList}
                    name={k.name}
                    city={k.city}
                    country={k.country}
                    connectivitytype={k.connectivitytype}
                    latitude={k.latitude}
                    longitude={k.longitude}
                    alertStatus={alStatus}
                    fullHeight
                    autoRedirect={autoRedirect}
                    location={this.props.location}
                    stillLoading={this.props.store.gateways.fromDashboard}
                  />
                </Col>
              );
            })}
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store: state,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSites,
      resetControllers,
      setControllersLoaded,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Plants);
