import React, { Component } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import OnDemandReports from "./OnDemandReports";
import ScheduledReports from "./ScheduledReports";
import "./reports.css";
import { AuthorizationContext } from "contexts/AuthorizationContext";

class Reports extends Component {
  static contextType = AuthorizationContext;

  constructor(props) {
    super(props);
    this.state = {
      activeTab: "onDemand",
    };
  }

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };

  render() {

    let licenses = [];

    Object.keys(this.props.gateways).forEach((gwGuid) => {
      const gw = this.props.gateways[gwGuid];

      for (let l of gw.licenses) {
        if (l.isActive) {
          licenses.push(JSON.parse(l.content));
          break;
        }
      }
    });

    let fqrEnabled = licenses.filter((l) => l.data.foodQualityReport.frontend === 1).length > 0;
    const userHasWritePermissionOnSite = this.context.hasWritePermission(this.props.site.siteGuid);


    return this.props.isReport ? (
      <>
        <Nav tabs className="mt-3 mx-4">
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === "onDemand",
              }) + " card-point"}
              onClick={() => this.toggleTab("onDemand")}
            >
              {this.props.t("report.onDemand")}
            </NavLink>
          </NavItem>
          <NavItem>
            {userHasWritePermissionOnSite && (
              <NavLink
                className={classnames({
                  active: this.state.activeTab === "scheduled",
                }) + " card-point"}
                onClick={() => this.toggleTab("scheduled")}
              >
                {this.props.t("report.scheduled")}
              </NavLink>
            )
            }
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="onDemand">
            <OnDemandReports {...this.props} fqrEnabled={fqrEnabled} />
          </TabPane>
          <TabPane tabId="scheduled">
            {<ScheduledReports {...this.props} fqrEnabled={fqrEnabled} />}
          </TabPane>
        </TabContent>
      </>
    ) : (
      <OnDemandReports {...this.props} fqrEnabled={fqrEnabled} />
    );
  }
}

export default Reports;
