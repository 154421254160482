import React, { Component } from "react";
import { Container } from "reactstrap";
import Performance from "../components/Performance";
import { connect } from "react-redux";

class PerformanceIndicators extends Component {
  state = {};
  render() {
    return (
      <Container fluid className="mt--9">
        <Performance {...this.props} />
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store: {
      gateways: state.gateways,
    },
  };
};

export default connect(mapStateToProps, null)(PerformanceIndicators);
