import React from "react";
import "./style.scss";

/**
 * Usage of react-notifications-component
 * 
 * Import the store and the default options
 *      import { Store as notifyStore } from "react-notifications-component";
        import {
            tempOptions,
            permanentOptions,
            NotifyContent,
        } from "./components/Notify";
 * 
 * 
 * Add new notifications
 *      
        notifyStore.addNotification({
          ...tempOptions, 
                    tempOptions leaves the notification for 5 seconds
                    permanentOptions leaves the notifications until the user clicks on it
          content: NotifyContent(
            type:<string> 
                    type is:    "default"   = #172b4d
                                "primary"   = #5e72e4
                                "info"      = #11cdef
                                "success"   = #2dce89
                                "danger"    = #f5365c
                                "warning"   = #fb6340
            
            title:<string>
            message:<string>
          ),
        });
 * 
 * For more information, visit https://www.npmjs.com/package/react-notifications-component
 */

export const tempOptions = {
  insert: "top",
  container: "top-right",
  animationIn: ["animated", "flipInY"],
  animationOut: ["animated", "bounceOut"],
  dismiss: {
    duration: 5000,
    onScreen: false,
  },
};

export const permanentOptions = {
  insert: "top",
  container: "top-right",
  animationIn: ["animated", "flipInY"],
  animationOut: ["animated", "bounceOut"],
};

export const NotifyContent = (type = "success", title = "", message = "") => {
  return (
    <div className={`notification-custom-${type}`}>
      <div className="notification-custom-icon" />
      <div className="notification-custom-content">
        <div className="notification-title">{title}</div>
        <p className="notification-message">{message}</p>
      </div>
    </div>
  );
};
