import SmartPlotly from "components/Chart/SmartPlotly";
import React, { Component } from "react";

class ConnectionChart extends Component {
  render() {
    let { title, coordinates, interval } = this.props;
    return (
      <SmartPlotly
        className="wifi-connection-chart"
        data={[
          {
            x: coordinates[0],
            y: coordinates[1],
            type: "scatter",
            mode: "lines+markers",
            marker: { color: "blue" },
            line: { shape: "hv" },
          },
        ]}
        layout={{
          title: title,
          margin: { t: 50, l: 80, r: 0, b: 38 },
          xaxis: {
            domain: [interval.start, interval.end],
          },
          yaxis: {
            range: [0, 1],
            tickmode: "array",
            tickvals: [0, 1],
            ticktext: ["OFF", "ON"],
          },
        }}
      />
    );
  }
}

export default ConnectionChart;
