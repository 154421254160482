import i18next from "i18next";
import en_GB from "./English_en-GB.json";
import { store } from "index";
import { setLanguages } from "redux/actions/index";
import api from "services/backendService";

export const selectLanguage = (lang = { "en-GB": { common: en_GB } }) => {
  i18next.init({
    interpolation: { escapeValue: false },
    lng: Object.keys(lang)[0],
    resources: lang,
    fallbackLng: Object.keys(lang)[0],
  });
};

export const setCurrentLanguage = async (language = null) => {
  const lang = language ?? (store.getState().user.language || "English_en-GB");
  const languages = (await api.getLanguagesList());
  const translations = (await api.getTranslations(lang)).data;
  store.dispatch(setLanguages({ list: languages }));

  const code = lang.split("_")[1];
  i18next.addResourceBundle(code, "common", translations);
  i18next.changeLanguage(code);
}