import React from "react";
import keys from "configs/constants";

class ProductLogo extends React.Component {
  render() {
    let clsName = "pt-0 pb-0 text-center d-flex-sm flex-column ";
    if (this.props.className !== undefined) {
      clsName += this.props.className;
    }

    return (
      <>
        <div className={clsName} style={{ whiteSpace: "normal" }}>
          <img
            alt="Logo"
            className={"navbar-brand-img  " + (this.props.logoWidth ?? "")}
            src={keys.LOGO_URL}
          />
          {!this.props.noText && (
            <>
              <h5 className="d-none d-md-block text-center text-dark mt-2">
                {/* Foodservice for big displays */}
                {keys.PRODUCT_NAME1}
              </h5>
              <h5 className="d-none d-md-block text-center text-dark mt--2">
                {/* DaMa Cloud for big displays */}
                {keys.PRODUCT_NAME2}
              </h5>
              <h5 className="d-md-none text-center text-dark mt-2">
                {/* Foodservice Name for small displays */}
                {keys.PRODUCT_NAME1}
              </h5>
              <h5 className="d-md-none text-center text-dark mt--2">
                {/* DaMa Cloud for small displays */}
                {keys.PRODUCT_NAME2}
              </h5>
            </>
          )}
        </div>
      </>
    );
  }
}

export default ProductLogo;
