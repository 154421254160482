import { SET_PARAMETER_FILE_LIST } from "../actions";

const initialState = {};

const parametersInfo = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case SET_PARAMETER_FILE_LIST:
      return payload;
    default:
      return state;
  }
};

export default parametersInfo;
