import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { renderToStaticMarkup } from "react-dom/server";
import {
  MapContainer as LeafletMap,
  TileLayer,
  Marker,
  Popup,
  useMap,
} from "react-leaflet";
import { divIcon } from "leaflet";
import utils from "utils";
import keys from "configs/constants";
import "./site.css";

function SensitiveMarker({
  position,
  id,
  popupInfo,
  alarms = [],
  offlineGateways = [],
  totalGateways = [],
}) {
  let icn =
    totalGateways.length === 0
      ? keys.ICON_MAP_MARKER_EMPTY_SITE
      : alarms.length === 0
        ? offlineGateways.length === 0
          ? utils.addClassToExistingElement(
              keys.ICON_MAP_MARKER_DEFAULT,
              "site-ok"
            )
          : offlineGateways.length === totalGateways.length
            ? utils.addClassToExistingElement(
                keys.ICON_MAP_MARKER_DEFAULT,
                "site-offline"
              )
            : utils.addClassToExistingElement(
                keys.ICON_MAP_MARKER_DEFAULT,
                "site-warn"
              )
        : utils.addClassToExistingElement(
            keys.ICON_MAP_MARKER_DEFAULT,
            "site-alarm"
          );

  const markerIcon = divIcon({
    html: renderToStaticMarkup(icn),
  });

  return (
    <Marker
      key={id}
      position={position}
      icon={markerIcon}
      zIndexOffset={alarms.length > 0 ? 1000 : 0}
      opacity={
        totalGateways.length > 0 &&
        offlineGateways.length === totalGateways.length &&
        alarms.length === 0
          ? 0.3
          : 1
      }
    >
      {popupInfo && <Popup>{popupInfo}</Popup>}
    </Marker>
  );
}

function MarkerMove({ lat, lng, zoom }) {
  const map = useMap();
  useEffect(() => {
    if (lat !== 0 || lng !== 0) {
      map.setView([lat, lng], zoom);
    }
  }, [lat, lng, zoom, map]);
  return null;
}

const OpenStreetMap = React.forwardRef(
  (
    {
      center = [46.144956, 12.324872],
      zoom = 8,
      markers = [],
      noHQ = false,
      onDragEnd,
    },
    mapRef
  ) => {
    const refMarker = useRef(null);

    const handleDragEnd = () => {
      if (onDragEnd && refMarker.current) {
        const { lat, lng } = refMarker.current.getLatLng();
        onDragEnd({ lat, lng });
      }
    };

    return (
      <LeafletMap
        center={center}
        zoom={zoom}
        className="leaflet-dashboard-map"
        ref={mapRef}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {noHQ ? (
          <Marker
            key={markers[0]?.siteGuid}
            position={[markers[0]?.latitude, markers[0]?.longitude]}
            draggable={true}
            ref={refMarker}
            eventHandlers={{
              dragend: handleDragEnd,
            }}
          />
        ) : (
          markers.map((storeInfo) => {
            if (storeInfo.longitude && storeInfo.latitude) {
              const pop =
                storeInfo.gateways.length > 0 ? (
                  <Link
                    to={{
                      pathname: "/std/dvcs",
                      site: storeInfo,
                    }}
                  >
                    <div>{storeInfo.name}</div>
                  </Link>
                ) : (
                  <div>{storeInfo.name}</div>
                );
              return (
                <SensitiveMarker
                  key={storeInfo.siteGuid}
                  id={storeInfo.siteGuid}
                  position={[storeInfo.latitude, storeInfo.longitude]}
                  icontType={storeInfo.connectivitytype}
                  popupInfo={pop}
                  alarms={storeInfo.alarms}
                  totalGateways={storeInfo.gateways}
                  offlineGateways={storeInfo.offlineGateways}
                />
              );
            } else {
              return null;
            }
          })
        )}
        <MarkerMove lat={center[0]} lng={center[1]} zoom={zoom} />
      </LeafletMap>
    );
  }
);

OpenStreetMap.displayName = "OpenStreetMap";

export default OpenStreetMap;
